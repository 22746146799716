import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { applyMiddleware, createStore, combineReducers } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import { createEpicMiddleware } from 'redux-observable';
import { RootAction, RootState, Services } from 'typesafe-actions';
import {
  entitiesReducer,
  queriesReducer,
  QueriesSelector,
  queryMiddleware,
} from 'redux-query';
import superagentInterface from 'redux-query-interface-superagent';
import DataferApiService from './network-interface';

import services from '../services';
import rootEpic from './root-epic';
import createRootReducer from './root-reducer';

export const history = createBrowserHistory({ basename: '/' });

const epicMiddleware = createEpicMiddleware<
  RootAction,
  RootAction,
  RootState,
  Services
>({ dependencies: services });

export const getQueries = (state: any) => state.queries;
export const getEntities = (state: any) => state.entities;

const middlewares = [
  routerMiddleware(history),
  epicMiddleware,
  queryMiddleware(
    (url, method, networkOptions) =>
      DataferApiService.awsApiGatewayNetworkInterface(
        url,
        method,
        networkOptions
      ),
    getQueries,
    getEntities
  ),
];
const composeEnhancers = composeWithDevTools({});
const enhancer = composeEnhancers(applyMiddleware(...middlewares));

const initialState = {};

const store = createStore(createRootReducer(history), initialState, enhancer);

epicMiddleware.run(rootEpic);

export default store;
