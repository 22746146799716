import React, { useEffect, useState } from 'react';
import * as inventoryActions from '../actions';
import { connect } from 'react-redux';
import { RootState } from 'typesafe-actions';
import * as inventorySelectors from '../selectors';
import { Button, TextField } from '@material-ui/core';

const mapStateToProps = (state: RootState) => ({
  user: inventorySelectors.user(state.inventory),
});

const dispatchProps = {
  switchUser: inventoryActions.switchUserAsync.request,
};

type Props = ReturnType<typeof mapStateToProps> & typeof dispatchProps;

const AuthGuard: React.FC<Props> = ({ user, switchUser }) => {
  const [inputValue, setSetInputValue] = useState<string>('mep');

  if (user?.role !== 'admin') {
    return null;
  }

  return (
    <div>
      <TextField
        id="outlined-basic"
        label="Imposter Mail"
        variant="outlined"
        onChange={it => setSetInputValue(it.target.value)}
      />
      <Button
        variant="contained"
        onClick={() =>
          switchUser({
            name: user!.name,
            role: user!.role,
            imposter: inputValue,
          })
        }
      >
        Change
      </Button>
    </div>
  );
};

export default connect(mapStateToProps, dispatchProps)(AuthGuard);
