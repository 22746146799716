import React, { Suspense } from 'react';
import AuthGuard from './features/inventory/components/AuthGuard';
import Loading from './components/Loading';
import MaintenanceBanner from './components/MaintenanceBanner';
import NetworkErrorDisplay from './features/inventory/components/NetworkErrorDisplay';
import Navbar from './components/Navbar';
import ImposterBar from './features/inventory/components/ImposterBar';
import ChangelogPopUp from './components/ChangelogPopUp';
import Routes from './routes';
import CustomAuthenticator from './features/authentication/CustomAuthenticator';

const CustomerApp: React.FC = () => {
  const bannerText =
    'Am 19.4.2021 werden in der Zeit von 17:00 bis 22:00Uhr geplante Wartungsarbeiten am Datafer Portal vorgenommen. Diese können zu Einschränkungen in der Verfügbarkeit des Programms führen. Wir bitten um Ihr Verständnis.';
  const endTimeMaintenance = Date.parse('2021-04-19T23:59:59.000Z');
  const deltaUntilMaintenanceEnd = endTimeMaintenance - Date.now();

  return (
    <CustomAuthenticator>
      <AuthGuard>
        <Suspense fallback={<Loading />}>
          {deltaUntilMaintenanceEnd > 0 ? (
            <MaintenanceBanner text={bannerText} />
          ) : (
            <div />
          )}
          <NetworkErrorDisplay />
          <Navbar />
          <ImposterBar />
          <ChangelogPopUp />
          <Routes />
        </Suspense>
      </AuthGuard>
    </CustomAuthenticator>
  );
};

export default CustomerApp;
