import React, { useState } from 'react';
import styles from './ChangelogPopUp.module.scss';
import IconClose from '@material-ui/icons/CloseRounded';
import { IconButton } from '@material-ui/core';
import classNames from 'classnames';
import horse from '../assets/images/horse.svg';

import tutorialExport from '../assets/images/tutorial-export.png';

const VERSIONS: Record<string, JSX.Element> = {
  '2021.05.04': (
    <div>
      <h3>Daten-Export</h3>
      <p>
        Mit der neuen Version des Datafer-Portals steht nun die Exportfunktion
        zur Verfügung!
      </p>
      <img src={tutorialExport} alt="Export Header" />
      <p>
        Diese ist in der Gastank Übersicht in der Statistik eines Tanks zu
        finden und stellt die für den gewählten Zeitraum visualisierten Daten
        als Datei im XLSX (Excel), CSV oder HTML Format zur Verfügung.{' '}
      </p>
      <h3>Changelogs</h3>
      <p>
        Eine weitere Neuerung in dieser Version haben Sie gerade vor sich:
        Mithilfe unserer neuen Changelogs wollen wir Sie zukünftig über alle
        wissenwerten Änderungen am Portal informieren, damit Sie stets das Beste
        aus dem Datafersystem herausholen können!
      </p>
      <h3>Fehlerbehebungen</h3>
      <p>
        Ein Fehler wurde behoben, durch den einige User nach dem Log-In nur eine
        weiße Seite zu Gesicht bekamen.
      </p>
      <p>Viel Spaß mit der neuen Version wünscht Ihr Datafer-Team!</p>
    </div>
  ),
};
const LOCAL_STORE_KEY = 'datafer-last-seen-version';

const ChangelogPopUp: React.FC = () => {
  const newestVersion = Object.keys(VERSIONS).sort()[0];
  const newestChangelog = VERSIONS[newestVersion];
  const [close, setClose] = useState(
    localStorage.getItem(LOCAL_STORE_KEY) === newestVersion
  );

  React.useEffect(() => {
    if (close) {
      localStorage.setItem(LOCAL_STORE_KEY, newestVersion);
    }
  });

  return (
    <div
      className={classNames(styles.background, { [styles.displayNone]: close })}
    >
      <div className={styles.container}>
        <div className={styles.close}>
          <IconButton
            onClick={() => {
              setClose(true);
            }}
          >
            <IconClose />
          </IconButton>
        </div>

        <div className={styles.content}>
          <span className={styles.illu}>
            <img src={horse} alt="" />
          </span>
          <div className={styles.text}>
            <h2>Neues in der Version {newestVersion}</h2>
            {newestChangelog}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangelogPopUp;
