import { createAsyncAction } from 'typesafe-actions';
import { AzureTokenConversionResponse } from '../../generated/api/src';

export interface DataferUser {
  name: string;
  role: string;
  imposter: string | null;
}

export const increaseCounterAsync = createAsyncAction(
  'INCREASE_COUNTER_REQUEST',
  'INCREASE_COUNTER_SUCCESS',
  'INCREASE_COUNTER_FAILURE'
)<undefined, number, Error>();

export const switchUserAsync = createAsyncAction(
  'SWITCH_USER_REQUEST',
  'SWITCH_USER_SUCCESS',
  'SWITCH_USER_FAILURE'
)<DataferUser | null, DataferUser | null, Error>();

export const signInWithAzureAsync = createAsyncAction(
  'SIGN_IN_AZURE_REQUEST',
  'SIGN_IN_AZURE_SUCCESS',
  'SIGN_IN_AZURE_FAILURE'
)<string, AzureTokenConversionResponse, Error>();

export const readSalesforceUserTokenFromStoreAsync = createAsyncAction(
  'READ_AZURE_TOKEN_REQUEST',
  'READ_AZURE_TOKEN_SUCCESS',
  'READ_AZURE_TOKEN_FAILURE'
)<undefined, AzureTokenConversionResponse, Error>();

export const writeSalesforceUserTokenToStoreAsync = createAsyncAction(
  'WRITE_AZURE_TOKEN_REQUEST',
  'WRITE_AZURE_TOKEN_SUCCESS',
  'WRITE_AZURE_TOKEN_FAILURE'
)<AzureTokenConversionResponse, undefined, Error>();
