import { ConnectedRouter } from 'connected-react-router';
import React from 'react';
import { Provider } from 'react-redux';
import { Provider as ReduxQueryProvider } from 'redux-query-react';
import store, { history } from './store';
import { Configuration, BASE_PATH } from './generated/api/src';
import FirewallCheckPopup from './components/FirewallCheckPopup';
import CustomerApp from './CustomerApp';
import { Route, Switch } from 'react-router';
import NoMatch from './components/NoMatch';
import SalesforceApp from './features/salesforce/SalesforceApp';
import AzureAuthenticatorLanding from './features/authentication/AzureAuthenticatorLanding';

Configuration.basePath = BASE_PATH;
export const getQueries = (state: any) => state.queries;

const App: React.FC = () => {
  return (
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <ReduxQueryProvider queriesSelector={getQueries}>
          <FirewallCheckPopup />
          <Switch>
            <Route path="/salesforce" component={SalesforceApp} />
            <Route
              exact
              path="/salesforcelanding"
              component={AzureAuthenticatorLanding}
            />
            <Route path="/" component={CustomerApp} />
            <Route path="*" component={NoMatch} />
          </Switch>
        </ReduxQueryProvider>
      </ConnectedRouter>
    </Provider>
  );
};

export default App;
