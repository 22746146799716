import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components';
import React, { useEffect, useState } from 'react';
import * as inventoryActions from '../actions';
import { connect } from 'react-redux';
import { RootState } from 'typesafe-actions';
import * as inventorySelectors from '../selectors';
import { Auth } from 'aws-amplify';
import styles from './AuthGuard.module.scss';
import { AmplifyButton } from '@aws-amplify/ui-react';

const mapStateToProps = (state: RootState) => ({
  user: inventorySelectors.user(state.inventory),
});

const dispatchProps = {
  switchUser: inventoryActions.switchUserAsync.request,
};

type Props = ReturnType<typeof mapStateToProps> & typeof dispatchProps;

interface AuthData {
  username: string;
  signInUserSession: {
    idToken: {
      payload: {
        'cognito:preferred_role': string;
      };
    };
  } | null;
}

const AuthGuard: React.FC<Props> = ({ user, switchUser, children }) => {
  const [authState, setAuthState] = useState<AuthState>();

  useEffect(() => {
    return onAuthUIStateChange((nextAuthState, data) => {
      setAuthState(nextAuthState);
      if (!data) {
        switchUser(null);
      } else if (nextAuthState === 'signedin') {
        const username = (data as { username: string }).username as
          | string
          | null;
        let roleClaim = 'none';

        const authDataPayload = (data as AuthData).signInUserSession!.idToken
          .payload;
        if (!Object.keys(authDataPayload).includes('cognito:preferred_role')) {
          console.error('Role not found in id token.');
          switchUser(null);
          return;
        }
        if (username !== null) {
          roleClaim = authDataPayload['cognito:preferred_role'] as string;
          roleClaim =
            roleClaim.indexOf('datafer-cognito-admin-role') != -1
              ? 'admin'
              : 'user';
        }

        switchUser(
          username ? { name: username!, role: roleClaim, imposter: null } : null
        );
      }
    });
  }, []);

  const shouldDisplay = !!user;
  return shouldDisplay ? (
    <>{children}</>
  ) : (
    <div className={styles.container}>
      <h2>There is something wrong with your account</h2>
      <div>Please contact Westfalen to be able to access the application.</div>
      <AmplifyButton variant="button" onClick={() => Auth.signOut()}>
        Back to sign-in
      </AmplifyButton>
    </div>
  );
};

export default connect(mapStateToProps, dispatchProps)(AuthGuard);
