// tslint:disable
/**
 * Datafer
 * Schnittstellenbeschreibung für die Kommunikation zwischen dem neuen Dataferfrontend und Cloud-AI Backend
 *
 * The version of the OpenAPI document: 0.0.2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
  HttpMethods,
  QueryConfig,
  ResponseBody,
  ResponseText,
} from 'redux-query';
import * as runtime from '../runtime';
import {
  Customer,
  CustomerFromJSON,
  CustomerToJSON,
  ErrorMessage,
  ErrorMessageFromJSON,
  ErrorMessageToJSON,
  Partner,
  PartnerFromJSON,
  PartnerToJSON,
  RegisterRequest,
  RegisterRequestFromJSON,
  RegisterRequestToJSON,
} from '../models';

export interface ExtendCustomerRequest {
  registerRequest: RegisterRequest;
}

export interface RegisterCustomerRequest {
  registerRequest: RegisterRequest;
}

/**
 * Erweitert einen bestehenden Kunden um eine Kundenummer
 */
function extendCustomerRaw<T>(
  requestParameters: ExtendCustomerRequest,
  requestConfig: runtime.TypedQueryConfig<T, void> = {}
): QueryConfig<T> {
  if (
    requestParameters.registerRequest === null ||
    requestParameters.registerRequest === undefined
  ) {
    throw new runtime.RequiredError(
      'registerRequest',
      'Required parameter requestParameters.registerRequest was null or undefined when calling extendCustomer.'
    );
  }

  let queryParameters: any = null;

  const headerParameters: runtime.HttpHeaders = requestConfig?.options?.headers
    ? { ...requestConfig.options.headers }
    : {};

  headerParameters['Content-Type'] = 'application/json';

  const { meta = {} } = requestConfig;

  meta.authType = ['api_key', 'header'];
  meta.authType = ['api_key', 'header'];
  const config: QueryConfig<T> = {
    url: `${runtime.Configuration.basePath}/partner/extend`,
    meta,
    update: requestConfig.update,
    queryKey: requestConfig.queryKey,
    optimisticUpdate: requestConfig.optimisticUpdate,
    force: requestConfig.force,
    rollback: requestConfig.rollback,
    options: {
      method: 'POST',
      headers: headerParameters,
    },
    body:
      queryParameters ||
      RegisterRequestToJSON(requestParameters.registerRequest),
  };

  const { transform: requestTransform } = requestConfig;
  if (requestTransform) {
  }

  return config;
}

/**
 * Erweitert einen bestehenden Kunden um eine Kundenummer
 */
export function extendCustomer<T>(
  requestParameters: ExtendCustomerRequest,
  requestConfig?: runtime.TypedQueryConfig<T, void>
): QueryConfig<T> {
  return extendCustomerRaw(requestParameters, requestConfig);
}

/**
 * Gibt für diesen Partner alle Kundennummern zurück
 */
function getCustomerNumbersRaw<T>(
  requestConfig: runtime.TypedQueryConfig<T, Array<Customer>> = {}
): QueryConfig<T> {
  let queryParameters: any = null;

  const headerParameters: runtime.HttpHeaders = requestConfig?.options?.headers
    ? { ...requestConfig.options.headers }
    : {};

  const { meta = {} } = requestConfig;

  meta.authType = ['api_key', 'header'];
  meta.authType = ['api_key', 'header'];
  const config: QueryConfig<T> = {
    url: `${runtime.Configuration.basePath}/partner/customers`,
    meta,
    update: requestConfig.update,
    queryKey: requestConfig.queryKey,
    optimisticUpdate: requestConfig.optimisticUpdate,
    force: requestConfig.force,
    rollback: requestConfig.rollback,
    options: {
      method: 'GET',
      headers: headerParameters,
    },
    body: queryParameters,
  };

  const { transform: requestTransform } = requestConfig;
  if (requestTransform) {
    config.transform = (body: ResponseBody, text: ResponseBody) =>
      requestTransform(body.map(CustomerFromJSON), text);
  }

  return config;
}

/**
 * Gibt für diesen Partner alle Kundennummern zurück
 */
export function getCustomerNumbers<T>(
  requestConfig?: runtime.TypedQueryConfig<T, Array<Customer>>
): QueryConfig<T> {
  return getCustomerNumbersRaw(requestConfig);
}

/**
 * Gibt Informationen über den eingeloggten Partner zurück
 */
function getPartnerRaw<T>(
  requestConfig: runtime.TypedQueryConfig<T, Partner> = {}
): QueryConfig<T> {
  let queryParameters: any = null;

  const headerParameters: runtime.HttpHeaders = requestConfig?.options?.headers
    ? { ...requestConfig.options.headers }
    : {};

  const { meta = {} } = requestConfig;

  meta.authType = ['api_key', 'header'];
  meta.authType = ['api_key', 'header'];
  const config: QueryConfig<T> = {
    url: `${runtime.Configuration.basePath}/partner`,
    meta,
    update: requestConfig.update,
    queryKey: requestConfig.queryKey,
    optimisticUpdate: requestConfig.optimisticUpdate,
    force: requestConfig.force,
    rollback: requestConfig.rollback,
    options: {
      method: 'GET',
      headers: headerParameters,
    },
    body: queryParameters,
  };

  const { transform: requestTransform } = requestConfig;
  if (requestTransform) {
    config.transform = (body: ResponseBody, text: ResponseBody) =>
      requestTransform(PartnerFromJSON(body), text);
  }

  return config;
}

/**
 * Gibt Informationen über den eingeloggten Partner zurück
 */
export function getPartner<T>(
  requestConfig?: runtime.TypedQueryConfig<T, Partner>
): QueryConfig<T> {
  return getPartnerRaw(requestConfig);
}

/**
 * Registriert einen neuen Kunden für Datafer
 */
function registerCustomerRaw<T>(
  requestParameters: RegisterCustomerRequest,
  requestConfig: runtime.TypedQueryConfig<T, void> = {}
): QueryConfig<T> {
  if (
    requestParameters.registerRequest === null ||
    requestParameters.registerRequest === undefined
  ) {
    throw new runtime.RequiredError(
      'registerRequest',
      'Required parameter requestParameters.registerRequest was null or undefined when calling registerCustomer.'
    );
  }

  let queryParameters: any = null;

  const headerParameters: runtime.HttpHeaders = requestConfig?.options?.headers
    ? { ...requestConfig.options.headers }
    : {};

  headerParameters['Content-Type'] = 'application/json';

  const { meta = {} } = requestConfig;

  meta.authType = ['api_key', 'header'];
  const config: QueryConfig<T> = {
    url: `${runtime.Configuration.basePath}/partner/register`,
    meta,
    update: requestConfig.update,
    queryKey: requestConfig.queryKey,
    optimisticUpdate: requestConfig.optimisticUpdate,
    force: requestConfig.force,
    rollback: requestConfig.rollback,
    options: {
      method: 'POST',
      headers: headerParameters,
    },
    body:
      queryParameters ||
      RegisterRequestToJSON(requestParameters.registerRequest),
  };

  const { transform: requestTransform } = requestConfig;
  if (requestTransform) {
  }

  return config;
}

/**
 * Registriert einen neuen Kunden für Datafer
 */
export function registerCustomer<T>(
  requestParameters: RegisterCustomerRequest,
  requestConfig?: runtime.TypedQueryConfig<T, void>
): QueryConfig<T> {
  return registerCustomerRaw(requestParameters, requestConfig);
}
