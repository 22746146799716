import React, { useState } from 'react';
import { Button, Checkbox, Container, TextField } from '@material-ui/core';
import { EMAIL_REGEX } from './EditAccountPassword';
import { useMutation } from 'redux-query-react';
import {
  createUserAccount,
  resendUserInvitation,
} from '../../../generated/api/src';
import Loading from '../../../components/Loading';

const ResendInvitation: React.FC = () => {
  const [email, setEmail] = useState<string>('');

  const [{ isPending: invitationPending }, sendInvitationHandle] = useMutation(
    () =>
      resendUserInvitation({
        accountInvitationResendRequest: {
          email,
        },
      })
  );

  const onSendInvitation = () => {
    sendInvitationHandle()?.then(() => setEmail(''));
  };

  const currentEmailOk = email.match(EMAIL_REGEX) !== null;
  return (
    <>
      <h3>Einladung (erneut) versenden</h3>
      <div style={{ display: 'flex' }}>
        <TextField
          label={'Email-Adresse'}
          value={email}
          onChange={event => setEmail(event.target.value)}
          error={!currentEmailOk && email !== ''}
        />
        <Button
          variant="contained"
          style={{ marginLeft: '14px' }}
          disabled={!currentEmailOk}
          onClick={onSendInvitation}
        >
          {invitationPending ? (
            <Loading color={'secondary'} />
          ) : (
            'Einladung versenden'
          )}
        </Button>
      </div>
    </>
  );
};

export default ResendInvitation;
