import React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'typesafe-actions';
import Error from '../../../components/Error';

const mapStateToProps = (state: RootState) => ({
  errors: state.errors,
});

type Props = ReturnType<typeof mapStateToProps>;

function makeReadableError(responseText: string, responseBody: any) {
  if (responseText === 'Not Found') {
    if (responseBody?.error) {
      const errString = responseBody.error as string;

      if (errString.includes('PartnerSet')) {
        return 'Der Server konnte den angeforderten Kunden nicht finden.';
      }

      return 'Der Server konnte eine angeforderte Ressource nicht finden.';
    }
  }

  if (responseText === 'Internal Server Error') {
    const errString = (responseBody?.error ?? '') as string;
    if (errString.endsWith('An account with the given email already exists.')) {
      return 'Ein Account mit dieser Email-Adresse existiert bereits';
    } else if (
      errString.endsWith('User does not exist.') ||
      errString.endsWith('Requested user does not exist')
    ) {
      return 'Ein Account mit dieser Email-Adresse existiert nicht';
    }

    return `Der Server hat einen unerwarteten Fehler gemeldet. Zusätzliche informationen: ${responseBody?.error}`;
  }

  if (responseText === 'Gateway Timeout') {
    return 'Der Server konnte nicht rechtzeitig antworten. Versuchen Sie es später erneut.';
  }

  return responseText || 'Unbekannter Fehler';
}

const NetworkErrorDisplay: React.FC<Props> = ({ errors }) => {
  return (
    <div>
      {Object.keys(errors).map(it => {
        return (
          <Error
            error={makeReadableError(
              errors[it].responseText || '',
              errors[it].responseBody || {}
            )}
            key={it}
          />
        );
      })}
    </div>
  );
};

export default connect(mapStateToProps)(NetworkErrorDisplay);
