import React, { useRef } from 'react';
import { Button, Toolbar, Typography } from '@material-ui/core';
import styles from './Navbar.module.scss';
import logo from '../assets/images/westfalen_logo.svg';
import { connect, useSelector } from 'react-redux';
import { getPartner, Partner } from '../generated/api/src';
import { useRequest } from 'redux-query-react';
import { Auth } from 'aws-amplify';
import * as inventorySelectors from '../features/inventory/selectors';
import { RootState } from 'typesafe-actions';
import { Link } from 'react-router-dom';

const mapStateToProps = (state: RootState) => ({
  user: inventorySelectors.user(state.inventory),
});

type Props = ReturnType<typeof mapStateToProps>;

const Navbar: React.FC<Props> = ({ user }) => {
  const adminLink = useRef<HTMLAnchorElement | null>(null);

  let partnerInfo = useSelector((state: any) => {
    return state.entities.partner as Partner;
  });

  let headers = {};
  if (user?.imposter) {
    headers = { 'x-datafer-imposter': user.imposter };
  }
  const [{ isPending, status }, refresh] = useRequest(
    getPartner({
      transform: body => {
        return { partner: body };
      },
      update: {
        partner: (oldValue: any, newValue: any) => {
          return newValue;
        },
      },
      force: !!user?.imposter,
      queryKey: `getPartner-${user!.name}-${user!.imposter}`,
      options: {
        headers,
      },
    })
  );

  return (
    <div className={styles.container}>
      <Toolbar className={styles.navbar}>
        <img className={styles.logo} src={logo} alt="Logo der Westfalen AG" />
        {user?.role === 'admin' && (
          <>
            <Link to={'/admin'} ref={adminLink} style={{ display: 'none' }} />
            <Button onClick={() => adminLink?.current?.click()}>
              Zur Administration
            </Button>
          </>
        )}
        <Button color="secondary" onClick={() => Auth.signOut()}>
          Logout
        </Button>
      </Toolbar>
      {isPending || !partnerInfo ? null : (
        <div className={styles.user}>
          <Typography variant="button" component="span">
            Willkommen {partnerInfo!.firstName} {partnerInfo!.lastName}
          </Typography>
        </div>
      )}
    </div>
  );
};

export default connect(mapStateToProps)(Navbar);
