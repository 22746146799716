import React, { Suspense } from 'react';
import AuthGuard from '../inventory/components/AuthGuard';
import Loading from '../../components/Loading';
import AzureAuthenticator from '../authentication/AzureAuthenticator';
import Routes from './routes';

const SalesforceApp: React.FC = () => {
  return (
    <AzureAuthenticator>
      <AuthGuard>
        <Suspense fallback={<Loading />}>
          {/*  {deltaUntilMaintenanceEnd > 0 ? (*/}
          {/*    <MaintenanceBanner text={bannerText} />*/}
          {/*  ) : (*/}
          {/*    <div />*/}
          {/*  )}*/}
          {/*  <NetworkErrorDisplay />*/}
          {/*  <Navbar />*/}
          {/*  <ImposterBar />*/}
          {/*  <ChangelogPopUp />*/}
          {/*<InventoryPage displayCustomerSelect={false} />*/}
          <Routes />
        </Suspense>
      </AuthGuard>
    </AzureAuthenticator>
  );
};

export default SalesforceApp;
