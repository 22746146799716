// tslint:disable
/**
 * Datafer
 * Schnittstellenbeschreibung für die Kommunikation zwischen dem neuen Dataferfrontend und Cloud-AI Backend
 *
 * The version of the OpenAPI document: 0.0.2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface Measurement
 */
export interface Measurement {
  /**
   * Messwert des Tankes am Zeitpunkt
   * @type {number}
   * @memberof Measurement
   */
  value: number;
  /**
   * aka Messbeleg aka Mess Id aka MeasurementVoucher
   * @type {string}
   * @memberof Measurement
   */
  voucherId: string;
  /**
   * Zeitpunkt der Messung
   * @type {Date}
   * @memberof Measurement
   */
  createdAt: Date;
}

export function MeasurementFromJSON(json: any): Measurement {
  return {
    value: json['value'],
    voucherId: json['voucherId'],
    createdAt: new Date(json['createdAt']),
  };
}

export function MeasurementToJSON(value?: Measurement): any {
  if (value === undefined) {
    return undefined;
  }
  return {
    value: value.value,
    voucherId: value.voucherId,
    createdAt: value.createdAt.toISOString(),
  };
}
