import React from 'react';
import { Route, Switch } from 'react-router';
import NoMatch from '../../components/NoMatch';
import SalesforceApp from './SalesforceApp';
import InventoryPage from '../inventory/screens/InventoryPage';

const Routes: React.FC = () => {
  return (
    <div>
      <Switch>
        <Route
          path="/salesforce/inventory/:customerId"
          component={() => <InventoryPage displayCustomerSelect={false} />}
        />
        <Route exact path="/salesforce/landing" component={SalesforceApp} />
        <Route exact path="/salesforce" component={SalesforceApp} />
        {/*<Route path="*" component={NoMatch} />*/}
      </Switch>
    </div>
  );
};

export default Routes;
