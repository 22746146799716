import React, { useState } from 'react';
import styles from './MaintenanceBanner.module.scss';
import IconEngineer from '@material-ui/icons/EngineeringRounded';
import IconClose from '@material-ui/icons/CloseRounded';
import { IconButton } from '@material-ui/core';
import classNames from 'classnames';

type Props = {
  text?: string;
};

const MaintenanceBanner: React.FC<Props> = ({ text }) => {
  const [close, setClose] = useState(false);
  return (
    <div
      className={classNames(styles.container, { [styles.displayNone]: close })}
    >
      <IconEngineer />
      <p>{text}</p>
      <div className={styles.close}>
        <IconButton onClick={() => setClose(true)}>
          <IconClose />
        </IconButton>
      </div>
    </div>
  );
};

export default MaintenanceBanner;
