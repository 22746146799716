// tslint:disable
/**
 * Datafer
 * Schnittstellenbeschreibung für die Kommunikation zwischen dem neuen Dataferfrontend und Cloud-AI Backend
 *
 * The version of the OpenAPI document: 0.0.2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface AccountCreationRequest
 */
export interface AccountCreationRequest {
  /**
   *
   * @type {string}
   * @memberof AccountCreationRequest
   */
  email: string;
  /**
   *
   * @type {boolean}
   * @memberof AccountCreationRequest
   */
  sendInvitation: boolean;
}

export function AccountCreationRequestFromJSON(
  json: any
): AccountCreationRequest {
  return {
    email: json['email'],
    sendInvitation: json['sendInvitation'],
  };
}

export function AccountCreationRequestToJSON(
  value?: AccountCreationRequest
): any {
  if (value === undefined) {
    return undefined;
  }
  return {
    email: value.email,
    sendInvitation: value.sendInvitation,
  };
}
