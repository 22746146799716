import React, { useRef, useState, useLayoutEffect } from 'react';
import Chart from 'chart.js';
import { Measurement } from '../../../generated/api/src';

interface Props {
  data: Measurement[];
  unit?: string;
}

const LineChart: React.FC<Props> = ({ data, unit }) => {
  const chartRef = useRef<HTMLCanvasElement>(null);
  const [chart, setChart] = useState(undefined as Chart | undefined);

  useLayoutEffect(() => {
    if (!chartRef.current) {
      return;
    }

    const ctx = chartRef.current.getContext('2d');
    Chart.defaults.global.defaultFontFamily = '"Source Sans Pro", sans-serif';
    Chart.defaults.global.defaultFontSize = 10;
    Chart.defaults.global.defaultFontColor = 'rgba(0, 49, 105, 0.5)';

    if (!ctx) {
      return;
    }

    const chartData = data.map(({ createdAt, value }) => ({
      t: createdAt,
      y: value,
    }));

    if (!chart) {
      setChart(
        new Chart(ctx, {
          data: {
            datasets: [
              {
                data: chartData,
                type: 'line',
                borderColor: '#003169',
                borderWidth: 2,
                fill: 1,
                lineTension: 0,
                spanGaps: true,
                pointRadius: 0,
                pointHitRadius: 10,
                pointHoverRadius: 16,
                pointHoverBackgroundColor: 'rgba(235, 102, 23, 0.1)',
                pointHoverBorderColor: 'transparent',
              },
            ],
          },
          options: {
            scales: {
              xAxes: [
                {
                  type: 'time',
                  distribution: 'series',
                  bounds: 'data',
                  time: {
                    tooltipFormat: 'DD.MM.YY HH:mm',
                    displayFormats: {
                      hour: 'DD.MM, HH [Uhr]',
                      day: 'DD.MM.YY',
                      week: 'DD.MM.YY',
                      month: 'MM.YYYY',
                      quarter: 'MM.YYYY',
                      year: 'MM.YYYY',
                    },
                  },
                  ticks: {
                    major: {
                      enabled: true,
                      fontStyle: 'bold',
                    },
                    source: 'data',
                    autoSkip: true,
                    autoSkipPadding: 20,
                    maxRotation: 90,
                    sampleSize: 100,
                  },
                  gridLines: {
                    color: 'rgba(0, 49, 105, 0.2)',
                    drawBorder: true,
                    drawOnChartArea: false,
                  },
                },
              ],
              yAxes: [
                {
                  type: 'linear',
                  gridLines: {
                    color: 'rgba(0, 49, 105, 0.2)',
                    drawBorder: true,
                    drawOnChartArea: false,
                  },
                  ticks: {
                    callback(
                      value: number | string,
                      index: number,
                      values: number[] | string[]
                    ): string | number | null | undefined {
                      return `${value} ${unit}`;
                    },
                  },
                },
              ],
            },
            legend: {
              display: false,
            },
            tooltips: {
              backgroundColor: 'rgb(235, 102, 23)',
              titleFontSize: 12,
              bodyFontSize: 12,
              cornerRadius: 10,
              displayColors: false,
              xPadding: 10,
              yPadding: 10,
              callbacks: {
                label(
                  tooltipItem: Chart.ChartTooltipItem,
                  data: Chart.ChartData
                ): string | string[] {
                  return `${tooltipItem.value} ${unit}`;
                },
              },
            },
            animation: {
              duration: 0,
            },
          },
        })
      );
    } else {
      chart.data.datasets![0].data = chartData;
      chart.update();
    }
  }, [data, chart, setChart]);

  return <canvas id="chart" ref={chartRef} width="100px" height="100px" />;
};

export default LineChart;
