import React, { useEffect, useState } from 'react';
import { I18n } from 'aws-amplify';
import { Hub, HubCallback } from '@aws-amplify/core';
import { AmplifyAuthenticator, AmplifySignIn } from '@aws-amplify/ui-react';
import {
  AuthState,
  onAuthUIStateChange,
  Translations,
  UI_AUTH_CHANNEL,
  TOAST_AUTH_ERROR_EVENT,
} from '@aws-amplify/ui-components';
import AuthAlert from './components/AuthAlert';
import { Container } from '@material-ui/core';
import CustomSignUp from './components/CustomSignUp';
import styles from './CustomAuthenticator.module.scss';
import logo from '../../assets/images/westfalen_logo.svg';
import Spacer from '../../components/Spacer';
import DataferTranslation from '../../assets/i18n/DataferTranslations';

const passwordTips =
  'Ihr Passwort muss mindestens 8 Zeichen lang sein, Klein- und Großbuchstaben, sowie Zahlen und Sonderzeichen enthalten.';

const DataferTranslationValues = {
  [DataferTranslation.CUSTOMER_NUMBER_LABEL]: 'Kundennummer',
  [DataferTranslation.CUSTOMER_NUMBER_PLACEHOLDER]:
    'Ihre Westfalen Kundenummer',
  [DataferTranslation.INVOICE_NUMBER_LABEL]: 'Rechnungsnummer',
  [DataferTranslation.INVOICE_NUMBER_PLACEHOLDER]:
    'Eine Westfalen Rechnungsnummer',
  [DataferTranslation.SIGNUP_CONTINUE]: 'Weiter',
  [DataferTranslation.SIGNUP_DONE_TEXT]:
    'Die Registierung wurde erfolgreich abgeschlossen. Innerhalb der nächsten Minuten sollten Sie eine E-Mail erhalten, die einen Bestätigungscode enthält. Um Ihren Account zu verifizieren müssen Sie diesen Code bei der ersten Anmeldung im System angeben.',
  [DataferTranslation.SIGNUP_TEXT]:
    'Bitte nutzen Sie zur Registrierung Ihre vorhandene Westfalen-Kundennummer sowie eine Rechnungsnummer aus den letzten drei Monaten.',
  [DataferTranslation.ERROR_SIGNUP_FORMAT_CUSTOMER_NO]:
    'Bitte überprüfen Sie das Format ihrer Kundenummer',
  [DataferTranslation.ERROR_SIGNUP_FORMAT_INVOICE_NO]:
    'Bitte überprüfen Sie das Alter und Format ihrer Rechnungsnummer',
};
I18n.putVocabulariesForLanguage('de-DE', DataferTranslationValues);

const passwordErrors = [
  'Password does not conform to policy: Password not long enough',
  'Password does not conform to policy: Password must have lowercase characters',
  'Password does not conform to policy: Password must have uppercase characters',
  'Password does not conform to policy: Password must have symbol characters',
  'Password does not conform to policy: Password must have numeric characters',
  "Password does not conform to policy: 1 validation error detected: Value at 'password' failed to satisfy constraint: Member must have length greater than or equal to 6",
  "1 validation error detected: Value at 'password' failed to satisfy constraint: Member must have length greater than or equal to 6",
];

const passwordErrorsPastTense = passwordErrors.map(it =>
  it.replace(/^Password does not conform to/, 'Password did not conform with')
);
const allpasswordErrors = [...passwordErrors, ...passwordErrorsPastTense];
const passwordErrorTranslations = Object.fromEntries(
  allpasswordErrors.map(it => [[it], passwordTips])
);

I18n.putVocabulariesForLanguage('de-DE', {
  [Translations.USERNAME_LABEL]: 'Email-Adresse', // email === username
  [Translations.USERNAME_PLACEHOLDER]: 'Ihre E-Mail-Adresse',
  [Translations.EMAIL_LABEL]: 'Email-Adresse',
  [Translations.EMAIL_PLACEHOLDER]: 'Ihre Email-Adresse',
  [Translations.PASSWORD_LABEL]: 'Passwort',
  [Translations.PASSWORD_PLACEHOLDER]: 'Ihr Passwort',
  [Translations.CONFIRM]: 'Bestätigen',
  [Translations.CONFIRM_SIGN_UP_HEADER_TEXT]: 'Anmeldung bestätigen',
  [Translations.CONFIRM_SIGN_UP_CODE_LABEL]: 'Bestätigungscode',
  [Translations.CONFIRM_SIGN_UP_CODE_PLACEHOLDER]: 'Code eingeben',
  [Translations.CONFIRM_SIGN_UP_LOST_CODE]: 'Code verloren?',
  [Translations.CONFIRM_SIGN_UP_RESEND_CODE]: 'Erneut senden',
  [Translations.FORGOT_PASSWORD_TEXT]: 'Passwort vergessen?',
  [Translations.RESET_PASSWORD_TEXT]: 'Passwort zurücksetzen',
  [Translations.SIGN_IN_ACTION]: 'Anmelden',
  [Translations.BACK_TO_SIGN_IN]: 'Zurück zur Anmeldung',
  [Translations.SEND_CODE]: 'Code senden',
  [Translations.RESET_YOUR_PASSWORD]: 'Ihr Passwort zurücksetzen',
  [Translations.CODE_LABEL]: 'Einmalcode',
  [Translations.CODE_PLACEHOLDER]: 'Ihr Einmalcode...',
  [Translations.NEW_PASSWORD_LABEL]: 'Passwort',
  [Translations.NEW_PASSWORD_PLACEHOLDER]: 'Ihr neues Passwort...',
  [Translations.SUBMIT]: 'Bestätigen',
  [Translations.SIGN_UP_HEADER_TEXT]: 'Neuen Datafer-Account anlegen',
  [Translations.SIGN_UP_HAVE_ACCOUNT_TEXT]: 'Sie haben schon einen Account?',
  [Translations.SIGN_IN_TEXT]: 'Einloggen',
  [Translations.SIGN_UP_SUBMIT_BUTTON_TEXT]: 'Account anlegen',
  [Translations.CREATE_ACCOUNT_TEXT]: 'Account anlegen',
  [Translations.NO_ACCOUNT_TEXT]: 'Sie haben noch keinen Account?',
  ['An account with the given email already exists.']:
    'Es existiert bereits ein Account mit dieser Email-Adresse',
  ['Password cannot be empty']: 'Das Passwort darf nicht leer sein',
  ['User not confirmed']: 'Der Account wurde noch nicht bestätigt',
  ...passwordErrorTranslations,
});
I18n.setLanguage('de-DE');

const CustomAuthenticator: React.FC = ({ children }) => {
  const [authState, setAuthState] = useState<AuthState>();
  const [user, setUser] = useState<object | undefined>();
  const [alertMessage, setAlertMessage] = useState('');

  const handleToastErrors: HubCallback = ({ payload }) => {
    if (payload.event === TOAST_AUTH_ERROR_EVENT) {
      setAlertMessage(payload.message ?? '');
    }
  };

  useEffect(() => {
    return onAuthUIStateChange((nextAuthState, authData) => {
      setAuthState(nextAuthState);
      setUser(authData);
    });
  }, []);

  useEffect(() => {
    Hub.listen(UI_AUTH_CHANNEL, handleToastErrors);
    return () => Hub.remove(UI_AUTH_CHANNEL, handleToastErrors);
  });

  return authState === AuthState.SignedIn && user ? (
    <AmplifyAuthenticator>{children}</AmplifyAuthenticator>
  ) : (
    <Container>
      <div className={styles.container}>
        <img className={styles.logo} src={logo} alt="Logo der Westfalen AG" />
        <Spacer />
        <AmplifyAuthenticator hideToast>
          <AmplifySignIn headerText="Datafer" slot="sign-in">
            <div slot="secondary-footer-content" />{' '}
            {/* todo: remove this and reenable CustomSignUp to show the signup screen */}
          </AmplifySignIn>
          {/*<CustomSignUp />*/}
          {children}
        </AmplifyAuthenticator>
        {alertMessage && <AuthAlert alert={alertMessage} />}
      </div>
    </Container>
  );
};

export default CustomAuthenticator;
