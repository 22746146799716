enum DataferTranslation {
  CUSTOMER_NUMBER_LABEL = 'Customer number',
  CUSTOMER_NUMBER_PLACEHOLDER = 'WAG customer number',
  INVOICE_NUMBER_LABEL = 'Invoice number',
  INVOICE_NUMBER_PLACEHOLDER = 'WAG invoice number',
  SIGNUP_CONTINUE = 'Continue',
  SIGNUP_DONE_TEXT = 'signup done text',
  SIGNUP_TEXT = 'landing text',
  ERROR_SIGNUP_FORMAT_CUSTOMER_NO = 'PreSignUp failed with error The signup request failed as one of the verification parameters was invalid: check customer number.',
  ERROR_SIGNUP_FORMAT_INVOICE_NO = 'PreSignUp failed with error The signup request failed as one of the verification parameters was invalid: check invoice number.',
}

export default DataferTranslation;
