// tslint:disable
/**
 * Datafer
 * Schnittstellenbeschreibung für die Kommunikation zwischen dem neuen Dataferfrontend und Cloud-AI Backend
 *
 * The version of the OpenAPI document: 0.0.2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import { TankOverview, TankOverviewFromJSON, TankOverviewToJSON } from './';

/**
 *
 * @export
 * @interface InlineResponse200
 */
export interface InlineResponse200 {
  /**
   *
   * @type {Array<TankOverview>}
   * @memberof InlineResponse200
   */
  tanks?: Array<TankOverview>;
}

export function InlineResponse200FromJSON(json: any): InlineResponse200 {
  return {
    tanks: !exists(json, 'tanks')
      ? undefined
      : (json['tanks'] as Array<any>).map(TankOverviewFromJSON),
  };
}

export function InlineResponse200ToJSON(value?: InlineResponse200): any {
  if (value === undefined) {
    return undefined;
  }
  return {
    tanks:
      value.tanks === undefined
        ? undefined
        : (value.tanks as Array<any>).map(TankOverviewToJSON),
  };
}
