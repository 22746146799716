import { createReducer } from 'typesafe-actions';
import {
  increaseCounterAsync,
  switchUserAsync,
  DataferUser,
  signInWithAzureAsync,
  readSalesforceUserTokenFromStoreAsync,
} from './actions';
import { AzureTokenConversionResponse } from '../../generated/api/src';
import {
  AsyncResource,
  asyncResourceFailure,
  asyncResourceRequest,
  asyncResourceSuccess,
} from '../../store/async-resource';

interface State {
  counter: number;
  user: DataferUser | null;
  awsTokens: AsyncResource<AzureTokenConversionResponse> | null;
}

const initialState = {
  counter: 0,
  user: null,
  awsTokens: null,
} as State;

export const inventoryReducer = createReducer(initialState)
  .handleAction(
    increaseCounterAsync.success,
    (state, { payload: newCounter }) => ({
      ...state,
      counter: newCounter,
    })
  )
  .handleAction(switchUserAsync.success, (state, { payload: newUser }) => ({
    ...state,
    user: newUser,
  }))
  .handleAction(
    readSalesforceUserTokenFromStoreAsync.success,
    (state, { payload: tokens }) => ({
      ...state,
      awsTokens: asyncResourceSuccess(tokens),
    })
  )
  .handleAction(
    readSalesforceUserTokenFromStoreAsync.failure,
    (state, { payload: error }) => ({
      ...state,
      awsTokens: asyncResourceFailure(error),
    })
  )
  .handleAction(signInWithAzureAsync.success, (state, { payload: tokens }) => ({
    ...state,
    awsTokens: asyncResourceSuccess(tokens),
  }))
  .handleAction(signInWithAzureAsync.request, state => ({
    ...state,
    awsTokens: asyncResourceRequest(),
  }));

export type InventoryState = ReturnType<typeof inventoryReducer>;
