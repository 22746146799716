import React, { useEffect, useState } from 'react';
import { TextField } from '@material-ui/core';
import AdapterMoment from '@material-ui/lab/AdapterMoment';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
import MobileDateTimePicker from '@material-ui/lab/MobileDateTimePicker';
import styles from './DateTimeRangePicker.module.scss';

interface Props {
  disabled?: boolean;
  initialStartDate: Date;
  initialEndDate: Date;
  startDateSetter: React.Dispatch<React.SetStateAction<Date>>;
  endDateSetter: React.Dispatch<React.SetStateAction<Date>>;
}

const DateTimeRangePicker: React.FC<Props> = ({
  disabled,
  initialStartDate,
  initialEndDate,
  startDateSetter,
  endDateSetter,
}) => {
  const [startDate, setStartDate] = useState<Date>(initialStartDate);
  const [endDate, setEndDate] = useState<Date>(initialEndDate);

  const maxTimeframe = 400 * 24 * 60 * 60 * 1000; // slightly more than a year

  const onAccept = (start: Date, end: Date) => {
    startDateSetter(start);
    endDateSetter(end);
  };

  const onAcceptStart = () => {
    let newEndDate = endDate;
    if (endDate.valueOf() < startDate.valueOf()) {
      newEndDate = new Date(startDate.valueOf() + 7 * 24 * 60 * 60 * 1000);
      setEndDate(newEndDate);
    }
    // clamp timeframe to ensure that the SAP can answer in time
    if (endDate.valueOf() - startDate.valueOf() > maxTimeframe) {
      newEndDate = new Date(startDate.valueOf() + maxTimeframe);
      setEndDate(newEndDate);
    }
    onAccept(startDate, newEndDate);
  };

  const onAcceptEnd = () => {
    let newStartDate = startDate;
    if (endDate.valueOf() < startDate.valueOf()) {
      newStartDate = new Date(endDate.valueOf() - 7 * 24 * 60 * 60 * 1000);
      setStartDate(newStartDate);
    }
    // clamp timeframe to ensure that the SAP can answer in time
    if (endDate.valueOf() - startDate.valueOf() > maxTimeframe) {
      newStartDate = new Date(endDate.valueOf() - maxTimeframe);
      setStartDate(newStartDate);
    }
    onAccept(newStartDate, endDate);
  };

  return (
    <div className={styles.container}>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <MobileDateTimePicker
          value={startDate}
          onChange={date => {
            if (date) {
              // The picker returns a "DateIOType", which makes this necessary
              // https://next.material-ui.com/zh/api/mobile-date-time-picker/
              setStartDate(new Date(Date.parse(date.toISOString())));
            }
          }}
          onAccept={onAcceptStart}
          renderInput={params => (
            <TextField {...params} variant="standard" disabled={disabled} />
          )}
        />
        <span>–</span>
        <MobileDateTimePicker
          value={endDate}
          onChange={date => {
            // The picker returns a "DateIOType", which makes this necessary
            // https://next.material-ui.com/zh/api/mobile-date-time-picker/
            if (date) {
              setEndDate(new Date(Date.parse(date.toISOString())));
            }
          }}
          onAccept={onAcceptEnd}
          renderInput={params => (
            <TextField {...params} variant="standard" disabled={disabled} />
          )}
        />
      </LocalizationProvider>
    </div>
  );
};

export default DateTimeRangePicker;
