// tslint:disable
/**
 * Datafer
 * Schnittstellenbeschreibung für die Kommunikation zwischen dem neuen Dataferfrontend und Cloud-AI Backend
 *
 * The version of the OpenAPI document: 0.0.2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface TankStatus
 */
export interface TankStatus {
  /**
   * Combination of SAP:CreatedDate and SAP:CreatedDate
   * @type {Date}
   * @memberof TankStatus
   */
  measurementCreatedAt: Date;
  /**
   * aka Serno_DFU aka Serno aka Serialnummer
   * @type {string}
   * @memberof TankStatus
   */
  deviceSerialNo: string;
  /**
   * Die Bestellmarke für den Füllstand
   * @type {number}
   * @memberof TankStatus
   */
  orderAlertFillLevel: number;
  /**
   * Datum, an dem der Tank leer ist, bei Betrachtung des Verbrauchs der letzten 3 Stunden
   * @type {Date}
   * @memberof TankStatus
   */
  reach3: Date;
  /**
   * Datum, an dem der Tank leer ist, bei Betrachtung des Verbrauchs der letzten 7 Stunden
   * @type {Date}
   * @memberof TankStatus
   */
  reach7: Date;
  /**
   * Datum, an dem der Tank leer ist, bei Betrachtung des Verbrauchs der letzten 24 Stunden
   * @type {Date}
   * @memberof TankStatus
   */
  reach24: Date;
  /**
   * Ablad(eplatz) im SAP, Anweisung für Liefertent etc. wo der Tank zu finden ist
   * @type {string}
   * @memberof TankStatus
   */
  unloadingArea: string;
}

export function TankStatusFromJSON(json: any): TankStatus {
  return {
    measurementCreatedAt: new Date(json['measurementCreatedAt']),
    deviceSerialNo: json['deviceSerialNo'],
    orderAlertFillLevel: json['orderAlertFillLevel'],
    reach3: new Date(json['reach3']),
    reach7: new Date(json['reach7']),
    reach24: new Date(json['reach24']),
    unloadingArea: json['unloadingArea'],
  };
}

export function TankStatusToJSON(value?: TankStatus): any {
  if (value === undefined) {
    return undefined;
  }
  return {
    measurementCreatedAt: value.measurementCreatedAt.toISOString(),
    deviceSerialNo: value.deviceSerialNo,
    orderAlertFillLevel: value.orderAlertFillLevel,
    reach3: value.reach3.toISOString(),
    reach7: value.reach7.toISOString(),
    reach24: value.reach24.toISOString(),
    unloadingArea: value.unloadingArea,
  };
}
