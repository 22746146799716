// tslint:disable
/**
 * Datafer
 * Schnittstellenbeschreibung für die Kommunikation zwischen dem neuen Dataferfrontend und Cloud-AI Backend
 *
 * The version of the OpenAPI document: 0.0.2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface AccountPasswordUpdateRequest
 */
export interface AccountPasswordUpdateRequest {
  /**
   *
   * @type {string}
   * @memberof AccountPasswordUpdateRequest
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof AccountPasswordUpdateRequest
   */
  password: string;
  /**
   *
   * @type {boolean}
   * @memberof AccountPasswordUpdateRequest
   */
  permanent: boolean;
}

export function AccountPasswordUpdateRequestFromJSON(
  json: any
): AccountPasswordUpdateRequest {
  return {
    email: json['email'],
    password: json['password'],
    permanent: json['permanent'],
  };
}

export function AccountPasswordUpdateRequestToJSON(
  value?: AccountPasswordUpdateRequest
): any {
  if (value === undefined) {
    return undefined;
  }
  return {
    email: value.email,
    password: value.password,
    permanent: value.permanent,
  };
}
