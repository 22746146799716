export interface AsyncResource<T> {
  data?: T;
  loading: boolean;
  error?: Error;
}

export function asyncResourceSelector<T>(resource?: AsyncResource<T>) {
  const s = resource;
  return {
    data: s?.data,
    loading: !!s?.loading,
    error: s?.error,
  };
}

export function asyncResourceRequest<T>(): AsyncResource<T> {
  return {
    data: undefined,
    loading: true,
    error: undefined,
  };
}

export function asyncOptimisticResourceRequest<T>(
  previousData: T
): AsyncResource<T> {
  return {
    data: previousData,
    loading: true,
    error: undefined,
  };
}

export function asyncResourceSuccess<T>(data: T): AsyncResource<T> {
  return {
    data,
    loading: false,
  };
}

export function asyncResourceFailure<T>(error: Error): AsyncResource<T> {
  return {
    loading: false,
    error,
  };
}
