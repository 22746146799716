// tslint:disable
/**
 * Datafer
 * Schnittstellenbeschreibung für die Kommunikation zwischen dem neuen Dataferfrontend und Cloud-AI Backend
 *
 * The version of the OpenAPI document: 0.0.2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface TankFillLevelOverview
 */
export interface TankFillLevelOverview {
  /**
   * Die erste Alarmgrenze für den Füllstand
   * @type {number}
   * @memberof TankFillLevelOverview
   */
  firstAlertFillLevel: number;
  /**
   * Die zweite Alarmgrenze für den Füllstand
   * @type {number}
   * @memberof TankFillLevelOverview
   */
  secondAlertFillLevel: number;
  /**
   * Vollmarke für den Füllstand. Originale Aussage wie folgt; --- Im Tankstatus war die Vollmarke bereits enthalten, allerdings als Wert im Feld MeasureScaleBound. Die Logik ist da so; Wenn Druck, dann steht in MeasureScaleBound der Maximale Betriebsdruck Wenn Füllstand, dann steht in MeasureScaleBound die Behältervollmarke\" -- In der Overview ist der Wert aber auch als Zzvollmarke enthalten
   * @type {number}
   * @memberof TankFillLevelOverview
   */
  maxFillLevel: number;
  /**
   * Der aktuelle Füllstand
   * @type {number}
   * @memberof TankFillLevelOverview
   */
  fillLevel: number;
  /**
   * Unit of measurement for fill level values
   * @type {string}
   * @memberof TankFillLevelOverview
   */
  fillLevelUnitOfMeasurement: string;
}

export function TankFillLevelOverviewFromJSON(
  json: any
): TankFillLevelOverview {
  return {
    firstAlertFillLevel: json['firstAlertFillLevel'],
    secondAlertFillLevel: json['secondAlertFillLevel'],
    maxFillLevel: json['maxFillLevel'],
    fillLevel: json['fillLevel'],
    fillLevelUnitOfMeasurement: json['fillLevelUnitOfMeasurement'],
  };
}

export function TankFillLevelOverviewToJSON(
  value?: TankFillLevelOverview
): any {
  if (value === undefined) {
    return undefined;
  }
  return {
    firstAlertFillLevel: value.firstAlertFillLevel,
    secondAlertFillLevel: value.secondAlertFillLevel,
    maxFillLevel: value.maxFillLevel,
    fillLevel: value.fillLevel,
    fillLevelUnitOfMeasurement: value.fillLevelUnitOfMeasurement,
  };
}
