// tslint:disable
/**
 * Datafer
 * Schnittstellenbeschreibung für die Kommunikation zwischen dem neuen Dataferfrontend und Cloud-AI Backend
 *
 * The version of the OpenAPI document: 0.0.2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import { Measurement, MeasurementFromJSON, MeasurementToJSON } from './';

/**
 *
 * @export
 * @interface TankHistory
 */
export interface TankHistory {
  /**
   * Druck im Tank über zeit
   * @type {Array<Measurement>}
   * @memberof TankHistory
   */
  pressureHistory: Array<Measurement>;
  /**
   * Füllstand im Tank über zeit
   * @type {Array<Measurement>}
   * @memberof TankHistory
   */
  fillLevelHistory: Array<Measurement>;
  /**
   * Unit of measurement for fill level datapoints
   * @type {string}
   * @memberof TankHistory
   */
  fillLevelUnitOfMeasurement: string;
  /**
   * Unit of measurement for pressure datapoints
   * @type {string}
   * @memberof TankHistory
   */
  pressureUnitOfMeasurement: string;
}

export function TankHistoryFromJSON(json: any): TankHistory {
  return {
    pressureHistory: (json['pressureHistory'] as Array<any>).map(
      MeasurementFromJSON
    ),
    fillLevelHistory: (json['fillLevelHistory'] as Array<any>).map(
      MeasurementFromJSON
    ),
    fillLevelUnitOfMeasurement: json['fillLevelUnitOfMeasurement'],
    pressureUnitOfMeasurement: json['pressureUnitOfMeasurement'],
  };
}

export function TankHistoryToJSON(value?: TankHistory): any {
  if (value === undefined) {
    return undefined;
  }
  return {
    pressureHistory: (value.pressureHistory as Array<any>).map(
      MeasurementToJSON
    ),
    fillLevelHistory: (value.fillLevelHistory as Array<any>).map(
      MeasurementToJSON
    ),
    fillLevelUnitOfMeasurement: value.fillLevelUnitOfMeasurement,
    pressureUnitOfMeasurement: value.pressureUnitOfMeasurement,
  };
}
