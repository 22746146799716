// tslint:disable
/**
 * Datafer
 * Schnittstellenbeschreibung für die Kommunikation zwischen dem neuen Dataferfrontend und Cloud-AI Backend
 *
 * The version of the OpenAPI document: 0.0.2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
  HttpMethods,
  QueryConfig,
  ResponseBody,
  ResponseText,
} from 'redux-query';
import * as runtime from '../runtime';
import {
  ErrorMessage,
  ErrorMessageFromJSON,
  ErrorMessageToJSON,
  InlineResponse200,
  InlineResponse200FromJSON,
  InlineResponse200ToJSON,
  TankDetail,
  TankDetailFromJSON,
  TankDetailToJSON,
  TankHistory,
  TankHistoryFromJSON,
  TankHistoryToJSON,
} from '../models';

export interface GetCustomerTankStatesRequest {
  customerNumber?: string;
}

export interface GetTankDetailsRequest {
  tankId: string;
}

export interface GetTankHistoryRequest {
  tankId: string;
  from: Date;
  to: Date;
}

export interface GetTankHistoryExportRequest {
  tankId: string;
  from: Date;
  to: Date;
  download: GetTankHistoryExportDownloadEnum;
}

/**
 * Gibt für die Übersichtsseite alle diesem Kunde zugeordneten Tanks mit deren Zustand zurück
 */
function getCustomerTankStatesRaw<T>(
  requestParameters: GetCustomerTankStatesRequest,
  requestConfig: runtime.TypedQueryConfig<T, InlineResponse200> = {}
): QueryConfig<T> {
  let queryParameters: any = null;

  queryParameters = {};

  if (requestParameters.customerNumber !== undefined) {
    queryParameters['customerNumber'] = requestParameters.customerNumber;
  }

  const headerParameters: runtime.HttpHeaders = requestConfig?.options?.headers
    ? { ...requestConfig.options.headers }
    : {};

  const { meta = {} } = requestConfig;

  meta.authType = ['api_key', 'header'];
  meta.authType = ['api_key', 'header'];
  const config: QueryConfig<T> = {
    url: `${runtime.Configuration.basePath}/partner/tanks/state`,
    meta,
    update: requestConfig.update,
    queryKey: requestConfig.queryKey,
    optimisticUpdate: requestConfig.optimisticUpdate,
    force: requestConfig.force,
    rollback: requestConfig.rollback,
    options: {
      method: 'GET',
      headers: headerParameters,
    },
    body: queryParameters,
  };

  const { transform: requestTransform } = requestConfig;
  if (requestTransform) {
    config.transform = (body: ResponseBody, text: ResponseBody) =>
      requestTransform(InlineResponse200FromJSON(body), text);
  }

  return config;
}

/**
 * Gibt für die Übersichtsseite alle diesem Kunde zugeordneten Tanks mit deren Zustand zurück
 */
export function getCustomerTankStates<T>(
  requestParameters: GetCustomerTankStatesRequest,
  requestConfig?: runtime.TypedQueryConfig<T, InlineResponse200>
): QueryConfig<T> {
  return getCustomerTankStatesRaw(requestParameters, requestConfig);
}

/**
 * Gibt Details zu diesem Tank zurück
 */
function getTankDetailsRaw<T>(
  requestParameters: GetTankDetailsRequest,
  requestConfig: runtime.TypedQueryConfig<T, TankDetail> = {}
): QueryConfig<T> {
  if (
    requestParameters.tankId === null ||
    requestParameters.tankId === undefined
  ) {
    throw new runtime.RequiredError(
      'tankId',
      'Required parameter requestParameters.tankId was null or undefined when calling getTankDetails.'
    );
  }

  let queryParameters: any = null;

  const headerParameters: runtime.HttpHeaders = requestConfig?.options?.headers
    ? { ...requestConfig.options.headers }
    : {};

  const { meta = {} } = requestConfig;

  meta.authType = ['api_key', 'header'];
  meta.authType = ['api_key', 'header'];
  const config: QueryConfig<T> = {
    url: `${runtime.Configuration.basePath}/tank/{tankId}/details`.replace(
      `{${'tankId'}}`,
      encodeURIComponent(String(requestParameters.tankId))
    ),
    meta,
    update: requestConfig.update,
    queryKey: requestConfig.queryKey,
    optimisticUpdate: requestConfig.optimisticUpdate,
    force: requestConfig.force,
    rollback: requestConfig.rollback,
    options: {
      method: 'GET',
      headers: headerParameters,
    },
    body: queryParameters,
  };

  const { transform: requestTransform } = requestConfig;
  if (requestTransform) {
    config.transform = (body: ResponseBody, text: ResponseBody) =>
      requestTransform(TankDetailFromJSON(body), text);
  }

  return config;
}

/**
 * Gibt Details zu diesem Tank zurück
 */
export function getTankDetails<T>(
  requestParameters: GetTankDetailsRequest,
  requestConfig?: runtime.TypedQueryConfig<T, TankDetail>
): QueryConfig<T> {
  return getTankDetailsRaw(requestParameters, requestConfig);
}

/**
 * Gibt alle historischen Messwerte für einen Tank im angegebenen Zeitraum zurück
 */
function getTankHistoryRaw<T>(
  requestParameters: GetTankHistoryRequest,
  requestConfig: runtime.TypedQueryConfig<T, TankHistory> = {}
): QueryConfig<T> {
  if (
    requestParameters.tankId === null ||
    requestParameters.tankId === undefined
  ) {
    throw new runtime.RequiredError(
      'tankId',
      'Required parameter requestParameters.tankId was null or undefined when calling getTankHistory.'
    );
  }

  if (requestParameters.from === null || requestParameters.from === undefined) {
    throw new runtime.RequiredError(
      'from',
      'Required parameter requestParameters.from was null or undefined when calling getTankHistory.'
    );
  }

  if (requestParameters.to === null || requestParameters.to === undefined) {
    throw new runtime.RequiredError(
      'to',
      'Required parameter requestParameters.to was null or undefined when calling getTankHistory.'
    );
  }

  let queryParameters: any = null;

  queryParameters = {};

  if (requestParameters.from !== undefined) {
    queryParameters['from'] = (requestParameters.from as any).toISOString();
  }

  if (requestParameters.to !== undefined) {
    queryParameters['to'] = (requestParameters.to as any).toISOString();
  }

  const headerParameters: runtime.HttpHeaders = requestConfig?.options?.headers
    ? { ...requestConfig.options.headers }
    : {};

  const { meta = {} } = requestConfig;

  meta.authType = ['api_key', 'header'];
  meta.authType = ['api_key', 'header'];
  const config: QueryConfig<T> = {
    url: `${runtime.Configuration.basePath}/tank/{tankId}/history`.replace(
      `{${'tankId'}}`,
      encodeURIComponent(String(requestParameters.tankId))
    ),
    meta,
    update: requestConfig.update,
    queryKey: requestConfig.queryKey,
    optimisticUpdate: requestConfig.optimisticUpdate,
    force: requestConfig.force,
    rollback: requestConfig.rollback,
    options: {
      method: 'GET',
      headers: headerParameters,
    },
    body: queryParameters,
  };

  const { transform: requestTransform } = requestConfig;
  if (requestTransform) {
    config.transform = (body: ResponseBody, text: ResponseBody) =>
      requestTransform(TankHistoryFromJSON(body), text);
  }

  return config;
}

/**
 * Gibt alle historischen Messwerte für einen Tank im angegebenen Zeitraum zurück
 */
export function getTankHistory<T>(
  requestParameters: GetTankHistoryRequest,
  requestConfig?: runtime.TypedQueryConfig<T, TankHistory>
): QueryConfig<T> {
  return getTankHistoryRaw(requestParameters, requestConfig);
}

/**
 * Gibt alle historischen Messwerte für einen Tank im angegebenen Zeitraum zurück als Datei zum herunterladen
 */
function getTankHistoryExportRaw<T>(
  requestParameters: GetTankHistoryExportRequest,
  requestConfig: runtime.TypedQueryConfig<T, string> = {}
): QueryConfig<T> {
  if (
    requestParameters.tankId === null ||
    requestParameters.tankId === undefined
  ) {
    throw new runtime.RequiredError(
      'tankId',
      'Required parameter requestParameters.tankId was null or undefined when calling getTankHistoryExport.'
    );
  }

  if (requestParameters.from === null || requestParameters.from === undefined) {
    throw new runtime.RequiredError(
      'from',
      'Required parameter requestParameters.from was null or undefined when calling getTankHistoryExport.'
    );
  }

  if (requestParameters.to === null || requestParameters.to === undefined) {
    throw new runtime.RequiredError(
      'to',
      'Required parameter requestParameters.to was null or undefined when calling getTankHistoryExport.'
    );
  }

  if (
    requestParameters.download === null ||
    requestParameters.download === undefined
  ) {
    throw new runtime.RequiredError(
      'download',
      'Required parameter requestParameters.download was null or undefined when calling getTankHistoryExport.'
    );
  }

  let queryParameters: any = null;

  queryParameters = {};

  if (requestParameters.from !== undefined) {
    queryParameters['from'] = (requestParameters.from as any).toISOString();
  }

  if (requestParameters.to !== undefined) {
    queryParameters['to'] = (requestParameters.to as any).toISOString();
  }

  if (requestParameters.download !== undefined) {
    queryParameters['download'] = requestParameters.download;
  }

  const headerParameters: runtime.HttpHeaders = requestConfig?.options?.headers
    ? { ...requestConfig.options.headers }
    : {};

  const { meta = {} } = requestConfig;

  meta.authType = ['api_key', 'header'];
  meta.authType = ['api_key', 'header'];
  const config: QueryConfig<T> = {
    url: `${runtime.Configuration.basePath}/tank/{tankId}/history/export`.replace(
      `{${'tankId'}}`,
      encodeURIComponent(String(requestParameters.tankId))
    ),
    meta,
    update: requestConfig.update,
    queryKey: requestConfig.queryKey,
    optimisticUpdate: requestConfig.optimisticUpdate,
    force: requestConfig.force,
    rollback: requestConfig.rollback,
    options: {
      method: 'GET',
      headers: headerParameters,
    },
    body: queryParameters,
  };

  const { transform: requestTransform } = requestConfig;
  if (requestTransform) {
    config.transform = (body: ResponseBody, text: ResponseBody) =>
      requestTransform(body, text);
  }

  return config;
}

/**
 * Gibt alle historischen Messwerte für einen Tank im angegebenen Zeitraum zurück als Datei zum herunterladen
 */
export function getTankHistoryExport<T>(
  requestParameters: GetTankHistoryExportRequest,
  requestConfig?: runtime.TypedQueryConfig<T, string>
): QueryConfig<T> {
  return getTankHistoryExportRaw(requestParameters, requestConfig);
}

/**
 * @export
 * @enum {string}
 */
export enum GetTankHistoryExportDownloadEnum {
  Csv = 'csv',
  Html = 'html',
  Xls = 'xls',
}
