import { Container, Typography } from '@material-ui/core';
import React, { useRef, useState, useLayoutEffect } from 'react';
import { useRequest } from 'redux-query-react';
import CustomerNumberSelect from '../components/CustomerNumberSelect';
import styles from './InventoryPage.module.scss';
import Card from '../components/Card';
import classNames from 'classnames';
import {
  getCustomerTankStates,
  TankOverview,
} from '../../../generated/api/src';
import { connect, useSelector } from 'react-redux';
import Loading from '../../../components/Loading';
import GasDivision from '../components/GasDivision';
import * as inventorySelectors from '../selectors';
import { RootState } from 'typesafe-actions';
import useWindowWidth from '../../../hooks/useWindowWidth';
import { useHistory, useLocation, useParams } from 'react-router';

// https://github.com/reduxjs/reselect#q-how-do-i-create-a-selector-that-takes-an-argument
const getCustomerTankOverviewSelector = (state: RootState) => {
  return state.entities.customerTankOverviews as TankOverview[];
};

const mapStateToProps = (state: RootState) => ({
  user: inventorySelectors.user(state.inventory),
});

interface ComponentsProps {
  displayCustomerSelect?: boolean;
}

type Props = ReturnType<typeof mapStateToProps> & ComponentsProps;

const InventoryPage: React.FC<Props> = ({ user, displayCustomerSelect }) => {
  const [preview, setPreview] = useState(false);
  const params = useParams() as { customerId?: string };
  const history = useHistory();
  const customerNo = params?.customerId;

  const customerTanks = useSelector(getCustomerTankOverviewSelector) || [];

  let headers = {};
  if (user?.imposter) {
    headers = { 'x-datafer-imposter': user.imposter };
  }
  const [{ isPending, status }, refresh] = useRequest(
    getCustomerTankStates(
      {
        customerNumber: displayCustomerSelect ?? true ? undefined : customerNo,
      },
      {
        transform: responseBody => {
          return {
            customerTankOverviews: responseBody.tanks,
          };
        },
        update: {
          customerTankOverviews: (oldValue, newValue) => {
            return newValue;
          },
        },
        force: !!user?.imposter,
        queryKey: `getCustomerTankStates-${user!.name}-${user!.imposter}`,
        options: { headers },
      }
    )
  );

  const selectedCustomerTanks =
    customerNo == null
      ? customerTanks
      : customerTanks.filter(it => it.customerNumber === customerNo);
  const uniqueGasTypes = Array.from(
    new Set(selectedCustomerTanks.map(it => it.material))
  ).sort((a, b) => (a > b ? 1 : -1));
  let cards = isPending ? (
    <div className={styles.loading}>
      <Loading />
    </div>
  ) : preview ? (
    <div>
      {uniqueGasTypes.map(it => (
        <GasDivision
          gas={it}
          entities={selectedCustomerTanks}
          key={`${it}-division`}
        />
      ))}
    </div>
  ) : (
    <div>
      {selectedCustomerTanks
        .sort((a, b) => (a.material > b.material ? 1 : -1))
        .map(it => (
          <Card id={it.techId} key={it.techId} />
        ))}
    </div>
  );

  let { width } = useWindowWidth();
  const sidebarRef = useRef<HTMLDivElement>(null);
  const [scrollableListWidth, setScrollableListWidth] = useState(0);

  useLayoutEffect(() => {
    if (sidebarRef && sidebarRef.current) {
      setScrollableListWidth(
        1280 - 56 - sidebarRef.current.offsetWidth + (width - 1280) / 2
      );
    }
  });

  return (
    <Container>
      <div className={styles.container}>
        {(displayCustomerSelect ?? true) && (
          <div className={styles.sidebar} ref={sidebarRef}>
            <h1>Aktuelle Bestände</h1>
            <CustomerNumberSelect
              customerNo={customerNo}
              parentCustomerNoSetter={id => {
                if (id == null) {
                  history.push('/inventory');
                } else {
                  history.push(`/inventory/${id}`);
                }
              }}
              user={user}
            />
          </div>
        )}

        <div className={styles.list}>
          <div className={styles.buttons}>
            <button
              onClick={() => setPreview(false)}
              className={classNames({ [styles.active]: !preview })}
            >
              <Typography variant="button" component="span">
                Gastank-Liste
              </Typography>
            </button>
            <button
              onClick={() => setPreview(true)}
              className={classNames({ [styles.active]: preview })}
            >
              <Typography variant="button" component="span">
                Füllstandsübersicht
              </Typography>
            </button>
          </div>
          <div
            className={styles.listItems}
            style={
              preview && width > 1280 && (displayCustomerSelect ?? true)
                ? { width: scrollableListWidth }
                : {}
            }
          >
            {cards}
          </div>
        </div>
      </div>
    </Container>
  );
};

export default connect(mapStateToProps)(InventoryPage);
