import React, { useEffect, useRef, useState } from 'react';
import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components';
import { Container } from '@material-ui/core';
import styles from './CustomAuthenticator.module.scss';
import logo from '../../assets/images/westfalen_logo.svg';
import { useLocation } from 'react-router';
import { Customer } from '../../generated/api/src';
import { connect, useSelector } from 'react-redux';
import AzureAuthenticatorLanding from './AzureAuthenticatorLanding';
import * as inventorySelectors from '../inventory/selectors';
import { RootState } from 'typesafe-actions';
import * as inventoryActions from '../inventory/actions';
import Loading from '../../components/Loading';
import { Auth } from 'aws-amplify';

const mapStateToProps = (state: RootState) => ({
  user: inventorySelectors.user(state.inventory),
  tokens: inventorySelectors.awsTokens(state.inventory),
});

const dispatchProps = {
  readTokensFromStore:
    inventoryActions.readSalesforceUserTokenFromStoreAsync.request,
  switchUser: inventoryActions.switchUserAsync.request,
};

type Props = ReturnType<typeof mapStateToProps> & typeof dispatchProps;

const AzureAuthenticator: React.FC<Props> = ({
  readTokensFromStore,
  tokens,
  children,
  user,
  switchUser,
}) => {
  const authButton = useRef<HTMLAnchorElement | null>(null);

  useEffect(() => {
    //always sign out the previous user on mount
    Auth.signOut();
  }, []);

  useEffect(() => {
    if (authButton == null || authButton.current == null) {
      return;
    }

    if (tokens == null) {
      readTokensFromStore();
      return;
    }

    if (tokens.error) {
      authButton.current!.click();
    }
  }, [tokens, authButton]);

  if (user != null && user.name !== 'salesforce-machineuser@tapdo.io') {
    return <Loading fullscreen />;
  }

  const redirectTarget = `${window.location.origin}/salesforcelanding`;
  const state = `${encodeURIComponent(window.location.pathname)}`;

  // tapdo playground
  // const azureTenantId = '521f6e4c-69df-446c-aca6-520b52234aaf'
  // const azureClientId = '8df16297-b5c8-470e-8db8-e5640eb35910'

  // WAG azure
  const azureTenantId = 'f425eabc-e5a3-4d85-b11a-4ad392e99767';
  const azureClientId = 'b693caa2-b990-4917-9ccf-91bdbe3de65e';
  const azureLink = `https://login.microsoftonline.com/${azureTenantId}/oauth2/v2.0/authorize?client_id=${azureClientId}&response_type=id_token&redirect_uri=${redirectTarget}&scope=user.read+openid+profile+email&response_mode=fragment&state=${state}&nonce=678910`;

  return user ? (
    <>{children}</>
  ) : (
    <Container>
      <a ref={authButton} href={azureLink} />
      <Loading fullscreen={true} />
    </Container>
  );
};

export default connect(mapStateToProps, dispatchProps)(AzureAuthenticator);
