import React, { useEffect, useRef, useState } from 'react';
import { useRequest } from 'redux-query-react';
import {
  AzureTokenConversionResponse,
  convertAzureToken,
} from '../../generated/api/src';
import { useHistory, useLocation } from 'react-router';
import { connect, useSelector } from 'react-redux';
import { Auth } from 'aws-amplify';
import { RootState } from 'typesafe-actions';

import { AuthState } from '@aws-amplify/ui-components';
import {
  CognitoAccessToken,
  CognitoIdToken,
  CognitoRefreshToken,
  CognitoUserSession,
} from 'amazon-cognito-identity-js';
import * as inventorySelectors from '../inventory/selectors';
import * as inventoryActions from '../inventory/actions';
import { signInWithAzureAsync } from '../inventory/actions';
import Loading from '../../components/Loading';

const mapStateToProps = (state: RootState) => ({
  user: inventorySelectors.user(state.inventory),
});

const dispatchProps = {
  signInWithAzure: inventoryActions.signInWithAzureAsync.request,
};

type Props = ReturnType<typeof mapStateToProps> & typeof dispatchProps;

const AzureAuthenticatorLanding: React.FC<Props> = ({
  user,
  signInWithAzure,
}) => {
  const [returnUrl, setReturnUrl] = useState<string>(window.location.origin);
  const [azureToken, setAzureToken] = useState<string>();

  const uri = useLocation();
  const history = useHistory();
  // console.log('params', uri.hash);

  useEffect(() => {
    if (user?.name === 'salesforce-machineuser@tapdo.io') {
      history.push(returnUrl);
    }
  }, [user, returnUrl]);

  useEffect(() => {
    if (uri && uri.hash !== '') {
      const parts = uri.hash
        .slice(1)
        .split('&')
        .map(it => it.split('='));
      const token = parts
        .filter(it => it[0] === 'id_token')
        .map(it => it[1])[0];
      if (token) {
        setAzureToken(token);
      }

      setReturnUrl(
        parts
          .filter(it => it[0] === 'state')
          .map(it => decodeURIComponent(it[1]))[0]
      );
    }
  }, [uri]);

  useEffect(() => {
    if (azureToken) {
      signInWithAzure(azureToken);
    }
  }, [azureToken]);

  return <Loading fullscreen />;
};

export default connect(
  mapStateToProps,
  dispatchProps
)(AzureAuthenticatorLanding);
