import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ThemeProvider } from '@material-ui/core';
import theme from './theme';
import './assets/styles/style.scss';
import 'moment/locale/de';
import moment from 'moment';
import Amplify, { Auth } from 'aws-amplify';

moment.locale('de');

Amplify.configure({
  Auth: {
    // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
    identityPoolId: 'eu-central-1:288c0e3e-bb89-432f-85bf-db8832e57b43',

    // REQUIRED - Amazon Cognito Region
    region: 'eu-central-1',

    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: 'eu-central-1_JWFYQyfW7',

    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: '6pcntlrd2a09jltqhcvbpnm91i',

    // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
    mandatorySignIn: true,

    // OPTIONAL - Configuration for cookie storage
    // Note: if the secure flag is set to true, then the cookie transmission requires a secure protocol
    // cookieStorage: {
    //   // REQUIRED - Cookie domain (only required if cookieStorage is provided)
    //   domain: '.yourdomain.com',
    //   // OPTIONAL - Cookie path
    //   path: '/',
    //   // OPTIONAL - Cookie expiration in days
    //   expires: 365,
    //   // OPTIONAL - See: https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie/SameSite
    //   sameSite: "strict" | "lax",
    //   // OPTIONAL - Cookie secure flag
    //   // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
    //   secure: true
    // },

    // OPTIONAL - customized storage object
    // storage: MyStorage,

    // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
    authenticationFlowType: 'USER_SRP_AUTH',
  },
  API: {
    endpoints: [
      {
        name: 'DataferBackend',
        endpoint: `https://cloud-api.net/datafer-${
          process.env.REACT_APP_STAGE === 'development' ||
          process.env.REACT_APP_STAGE === 'staging'
            ? 'staging'
            : 'production'
        }`,
        custom_header: async () => {
          const headers: { [header: string]: string } = {
            'x-api-key': `${
              process.env.REACT_APP_STAGE === 'development'
                ? 'staging'
                : process.env.REACT_APP_STAGE
            }Y6IXpwjyYD1b9bmKGZTSn1pWnCXbYkBfNkLOdLaa`,
          };

          let token = null;
          try {
            token = `Bearer ${(await Auth.currentSession())
              .getIdToken()
              .getJwtToken()}`;

            headers.AccessToken = token;
          } catch {}

          if (!headers.AccessToken) {
            try {
              token = JSON.parse(
                window.localStorage.getItem('SALESFORCEUSER_AWS_TOKENS') ?? '{}'
              ).cognitoIdToken;
              if (token != null) {
                headers.AccessToken = token;
              }
            } catch (e) {}
          }

          return headers;
        },
      },
      {
        name: 'FirewallCheckBackend',
        endpoint: `https://cloud-api.net/firewallcheck-${
          process.env.REACT_APP_STAGE === 'development' ||
          process.env.REACT_APP_STAGE === 'staging'
            ? 'staging'
            : 'production'
        }`,
      },
    ],
  },
});

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
