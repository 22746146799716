import API from '@aws-amplify/api';
import { from, Observable } from 'rxjs';

export function apiGet<T>(
  apiName: string,
  path: string,
  params: object | undefined = undefined,
  headers: object | undefined = undefined
): Promise<T> {
  return API.get(apiName, path, {
    queryStringParameters: params,
    headers,
    response: true,
  });
}

export function apiPost<T>(
  apiName: string,
  path: string,
  data: object,
  params: object | undefined = undefined,
  headers: object | undefined = undefined
): Promise<T> {
  return API.post(apiName, path, {
    body: data,
    queryStringParameters: params,
    headers,
    response: true,
  });
}

export function apiPut<T>(
  apiName: string,
  path: string,
  data: object,
  params: object | undefined = undefined,
  headers: object | undefined = undefined
): Promise<T> {
  return API.put(apiName, path, {
    body: data,
    queryStringParameters: params,
    headers,
    response: true,
  });
}

export function apiDelete<T>(
  apiName: string,
  path: string,
  params: object | undefined = undefined,
  headers: object | undefined = undefined
): Promise<T> {
  return API.del(apiName, path, {
    queryStringParameters: params,
    headers,
    response: true,
  });
}
