import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useRequest } from 'redux-query-react';
import { Customer, getCustomerNumbers } from '../../../generated/api/src';
import { FormControl, MenuItem, Select } from '@material-ui/core';
import Label from './Label';
import Loading from '../../../components/Loading';
import LabelValuePair from './LabelValuePair';
import { DataferUser } from '../actions';
import styles from './CustomerNumberSelect.module.scss';

interface Props {
  parentCustomerNoSetter: (id: string | null) => void;
  customerNo?: string;
  user: DataferUser | null;
}

const getCustomerNumbersSel = (state: any) => {
  return state.entities.customers as Customer[];
};

const CustomerNumberSelect: React.FC<Props> = ({
  parentCustomerNoSetter,
  customerNo,
  user,
}) => {
  const [contract, setContract] = useState('Alle');
  // const [customer, setCustomer] = useState<Customer | null>(null);

  const customers = useSelector(getCustomerNumbersSel) || [];
  const customer =
    customerNo != null
      ? customers.find(it => it.customerNumber === customerNo)
      : null;

  let headers = {};
  if (user?.imposter) {
    headers = { 'x-datafer-imposter': user.imposter };
  }
  const [{ isPending, status }, refresh] = useRequest(
    getCustomerNumbers({
      transform: responseBody => {
        return {
          customers: responseBody,
        };
      },
      update: {
        customers: (oldValue, newValue) => {
          return newValue;
        },
      },
      force: !!user?.imposter,
      queryKey: `getCustomerNumbers-${user!.name}-${user!.imposter}`,
      options: { headers },
    })
  );

  if (isPending) {
    return (
      <div className={styles.loading}>
        <Loading />
      </div>
    );
  }

  return (
    <FormControl variant="outlined" className={styles.formControl}>
      <LabelValuePair label="Kunde" value={customer?.name || 'Alle'} large />
      <div className={styles.spacer} />
      <Label label="Kundennummer" />
      <Select
        value={contract}
        onChange={e => {
          setContract(e.target.value as string);
          const num =
            customers.find(it => it.customerNumber === e.target.value) || null;
          parentCustomerNoSetter(num?.customerNumber || null);
        }}
      >
        <MenuItem value="Alle">
          <strong>ALLE</strong>
        </MenuItem>
        {customers
          .sort((a, b) => Number(a.customerNumber) - Number(b.customerNumber))
          .map(customer => (
            <MenuItem
              value={customer.customerNumber}
              key={customer.customerNumber}
            >
              {customer.customerNumber}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
};

export default CustomerNumberSelect;
