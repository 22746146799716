import React, { useState } from 'react';
import BarChart from './BarChart';
import IconBarChart from '@material-ui/icons/BarChartRounded';
import IconLineChart from '@material-ui/icons/ShowChartRounded';
import LabelValuePair from './LabelValuePair';
import classNames from 'classnames';
import styles from './Card.module.scss';
import CardDetail from './CardDetail';
import CardStatistics from './CardStatistics';
import { TankOverview } from '../../../generated/api/src';
import { useSelector } from 'react-redux';
import Loading from '../../../components/Loading';
import Spacer from '../../../components/Spacer';
import Label from './Label';

interface Props {
  id: string;
  preview?: boolean;
}

const Card: React.FC<Props> = ({ id, preview }) => {
  const [detail, setDetail] = useState(false);
  const [statistic, setStatistic] = useState(false);

  const tankOverview = useSelector((state: any) => {
    return ((state.entities.customerTankOverviews ||
      []) as TankOverview[]).find(it => it.techId === id);
  });

  if (!tankOverview) {
    return <Loading />;
  }

  const handleDetailClick = () => {
    setDetail(prev => !prev);
    setStatistic(false);
  };
  const handleStatisticClick = () => {
    setStatistic(prev => !prev);
    setDetail(false);
  };

  const expandedContent = () => {
    if (detail) {
      return <CardDetail id={id} />;
    } else if (statistic) {
      return <CardStatistics id={id} />;
    }
  };

  if (preview) {
    return (
      <div className={`${styles.container} ${styles.preview}`}>
        <Label label={id} />
        <Spacer />
        <div className={styles.bars}>
          {tankOverview.fillLevelOverview && (
            <div className={`${styles.bar} ${styles.barLeft}`}>
              <BarChart
                max={tankOverview.fillLevelOverview?.maxFillLevel || 1}
                value={tankOverview.fillLevelOverview?.fillLevel || 0}
                orderLimit={
                  tankOverview.fillLevelOverview?.firstAlertFillLevel ||
                  undefined
                }
              />
              <Label label="Füllst." />
            </div>
          )}
          {tankOverview.pressureOverview && (
            <div className={styles.bar}>
              <BarChart
                max={tankOverview.pressureOverview?.maxPressure || 1}
                value={tankOverview.pressureOverview?.pressure || 0}
              />
              <Label label="Druck" />
            </div>
          )}
        </div>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles.visible}>
        <LabelValuePair label={id} value={tankOverview.material} />

        <div className={styles.buttons}>
          <button
            onClick={() => handleDetailClick()}
            className={classNames({ [styles.active]: detail })}
          >
            {<IconBarChart />}
          </button>
          <button
            onClick={() => handleStatisticClick()}
            className={classNames({ [styles.active]: statistic })}
          >
            {<IconLineChart />}
          </button>
        </div>
      </div>
      {detail || statistic ? expandedContent() : null}
    </div>
  );
};

export default Card;
