import { Epic } from 'redux-observable';
import { from, of } from 'rxjs';
import { catchError, concatMap, filter, map, switchMap } from 'rxjs/operators';
import { isActionOf, RootAction, RootState, Services } from 'typesafe-actions';
import {
  DataferUser,
  increaseCounterAsync,
  readSalesforceUserTokenFromStoreAsync,
  signInWithAzureAsync,
  switchUserAsync,
  writeSalesforceUserTokenToStoreAsync,
} from './actions';
import { AzureTokenConversionResponse } from '../../generated/api/src';

export const increaseCounter: Epic<
  RootAction,
  RootAction,
  RootState,
  Services
> = (action$, _, { counter }) =>
  action$.pipe(
    filter(isActionOf(increaseCounterAsync.request)),
    switchMap(() =>
      counter.increaseCounter().pipe(
        map(result => increaseCounterAsync.success(result)),
        catchError(error => of(increaseCounterAsync.failure(error)))
      )
    )
  );

export const switchUser: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  _,
  { user }
) =>
  action$.pipe(
    filter(isActionOf(switchUserAsync.request)),
    switchMap(value => {
      return user.switchUser(value.payload).pipe(
        map(result => switchUserAsync.success(result)),
        catchError(error => of(switchUserAsync.failure(error)))
      );
    })
  );

export const signInWithAzure: Epic<
  RootAction,
  RootAction,
  RootState,
  Services
> = (action$, _, { user }) =>
  action$.pipe(
    filter(isActionOf(signInWithAzureAsync.request)),
    switchMap(value => {
      return user.signInWithAzure(value.payload).pipe(
        map((result): {
          tokens: AzureTokenConversionResponse;
          user: DataferUser;
        } => ({
          tokens: result,
          user: {
            imposter: null,
            name: 'salesforce-machineuser@tapdo.io',
            role: 'user',
          },
        })),
        concatMap(result =>
          from([
            signInWithAzureAsync.success(result.tokens),
            writeSalesforceUserTokenToStoreAsync.request(result.tokens),
            switchUserAsync.request(result.user),
          ])
        ),
        catchError(error => of(signInWithAzureAsync.failure(error)))
      );
    })
  );

export const readAzureTokenFromStore: Epic<
  RootAction,
  RootAction,
  RootState,
  Services
> = (action$, _, { user }) =>
  action$.pipe(
    filter(isActionOf(readSalesforceUserTokenFromStoreAsync.request)),
    switchMap(value => {
      return user.readAzureTokenFromStorage().pipe(
        map((result): {
          tokens: AzureTokenConversionResponse;
          user: DataferUser;
        } => ({
          tokens: result,
          user: {
            imposter: null,
            name: 'salesforce-machineuser@tapdo.io',
            role: 'user',
          },
        })),
        concatMap(result =>
          from([
            readSalesforceUserTokenFromStoreAsync.success(result.tokens),
            switchUserAsync.request(result.user),
          ])
        ),
        catchError(error =>
          of(readSalesforceUserTokenFromStoreAsync.failure(error))
        )
      );
    })
  );

export const writeAzureTokenToStore: Epic<
  RootAction,
  RootAction,
  RootState,
  Services
> = (action$, _, { user }) =>
  action$.pipe(
    filter(isActionOf(writeSalesforceUserTokenToStoreAsync.request)),
    switchMap(value => {
      return user.writeAzureTokenToStorage(value.payload).pipe(
        map(result => writeSalesforceUserTokenToStoreAsync.success()),
        catchError(error =>
          of(writeSalesforceUserTokenToStoreAsync.failure(error))
        )
      );
    })
  );
