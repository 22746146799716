import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { RootState } from 'typesafe-actions';
import * as inventorySelectors from '../selectors';
import { useSelector } from 'react-redux';
import {
  getTankHistoryExport,
  GetTankHistoryExportDownloadEnum,
} from '../../../generated/api/src';
import { useRequest } from 'redux-query-react';
import IconDownload from '@material-ui/icons/SaveAltRounded';
import { Button } from '@material-ui/core';
import styles from './CardStatistics.module.scss';
import IconCsv from '../../../assets/images/icon_csv.svg';
import IconXls from '../../../assets/images/icon_xls.svg';
import IconHtml from '../../../assets/images/icon_html.svg';
import Loading from '../../../components/Loading';

const mapStateToProps = (state: RootState) => ({
  user: inventorySelectors.user(state.inventory),
});

type Props = ReturnType<typeof mapStateToProps> & {
  id: string;
  from: Date;
  to: Date;
  format: GetTankHistoryExportDownloadEnum;
};

type PropsInternal = Props & {
  requested: boolean;
  onHrefChanged: (href: string) => void;
};

type DownloadLink = {
  href: string;
  key: string;
};

const HistoryDownloadButtonInternal: React.FC<PropsInternal> = ({
  id,
  from,
  to,
  user,
  format,
  requested,
  onHrefChanged,
}) => {
  const [href, setHref] = useState<DownloadLink>({ href: '', key: '' });

  const key = `${id}-${from}-${to}-${format}-${user!.name}-${user!.imposter}`;

  const entityStoreId = `${key}-history-export`;
  let tankHistoryExport = useSelector((state: any) => {
    return state.entities[entityStoreId] as Buffer;
  });

  let headers = {};
  if (user?.imposter) {
    headers = { 'x-datafer-imposter': user.imposter };
  }

  const [{ isPending, status }, refresh] = useRequest(
    getTankHistoryExport(
      { tankId: id, from: from, to: to, download: format },
      {
        transform: (body, text) => {
          return { [entityStoreId]: Buffer.from(body) };
        },
        update: {
          [entityStoreId]: (oldValue: any, newValue: any) => {
            return newValue;
          },
        },
        // force: !!user?.imposter,
        queryKey: `getTankHistoryExport-${key}`,
        options: {
          headers,
        },
      }
    )
  );

  if (href.key !== key) {
    if (href.href) {
      window.URL.revokeObjectURL(href.href);
      setHref({ key: key, href: '' });
    }

    if (!isPending && tankHistoryExport && requested) {
      let blob = null;
      switch (format) {
        case GetTankHistoryExportDownloadEnum.Csv:
          blob = new Blob([tankHistoryExport], { type: 'text/csv' });
          break;
        case GetTankHistoryExportDownloadEnum.Html:
          blob = new Blob([tankHistoryExport], { type: 'text/html' });
          break;
        case GetTankHistoryExportDownloadEnum.Xls:
          const decoded = Buffer.from(tankHistoryExport.toString(), 'base64');
          blob = new Blob([decoded], {
            type:
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          });
      }

      const url = window.URL.createObjectURL(blob);
      setHref({ key: key, href: url });
    }
  }
  useEffect(() => onHrefChanged(href.href));

  return <div />;
};

const HistoryDownloadButton: React.FC<Props> = ({
  id,
  from,
  to,
  user,
  format,
}) => {
  const [requested, setRequested] = useState(false);
  const [href, setHref] = useState('');
  const inputEl = useRef<HTMLAnchorElement>(null);

  let icon = null;
  let fileEnding = format.toString();
  switch (format) {
    case GetTankHistoryExportDownloadEnum.Csv:
      icon = IconCsv;
      break;
    case GetTankHistoryExportDownloadEnum.Xls:
      icon = IconXls;
      fileEnding = 'xlsx';
      break;
    case GetTankHistoryExportDownloadEnum.Html:
      icon = IconHtml;
      break;
  }

  useLayoutEffect(() => {
    inputEl?.current?.click();
  }, [href]);

  const historyDl = (
    <HistoryDownloadButtonInternal
      to={to}
      from={from}
      id={id}
      user={user}
      format={format}
      requested={requested}
      onHrefChanged={href => {
        setHref(href);
      }}
    />
  );
  let button = null;
  if (!requested) {
    button = (
      <Button
        color="secondary"
        className={styles.fileButton}
        onClick={() => setRequested(true)}
      >
        <img src={icon} alt={`${format.toUpperCase()}-Datei herunterladen`} />
        <IconDownload className={styles.icon} />
      </Button>
    );
  } else if (requested) {
    if (href === '') {
      button = (
        <div>
          <Button
            color="secondary"
            className={`${styles.fileButton} ${styles.fileButtonLoading}`}
          >
            <img
              src={icon}
              alt={`${format.toUpperCase()}-Datei herunterladen`}
            />
            <div className={styles.overlayLoading}>
              <Loading />
            </div>
          </Button>
          {historyDl}
        </div>
      );
    } else {
      button = (
        <div>
          <Button
            color="secondary"
            className={styles.fileButton}
            target={'_blank'}
            href={href}
            download={`export.${fileEnding}`}
            ref={inputEl}
          >
            <img
              src={icon}
              alt={`${format.toUpperCase()}-Datei herunterladen`}
            />
            <IconDownload className={styles.icon} />
          </Button>
          {historyDl}
        </div>
      );
    }
  }

  return <div>{button}</div>;
};

export default HistoryDownloadButton;
