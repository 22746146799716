import React from 'react';
import styles from './CardDetail.module.scss';
import BarChart from './BarChart';
import LabelValuePair from './LabelValuePair';
import { connect, useSelector } from 'react-redux';
import { useRequest } from 'redux-query-react';
import {
  getTankDetails,
  TankDetail,
  TankOverview,
  TankStatus,
} from '../../../generated/api/src';
import Loading from '../../../components/Loading';
import * as inventorySelectors from '../selectors';
import { RootState } from 'typesafe-actions';
import Spacer from '../../../components/Spacer';

const mapStateToProps = (state: RootState) => ({
  user: inventorySelectors.user(state.inventory),
});

type Props = ReturnType<typeof mapStateToProps> & { id: string };

const CardDetail: React.FC<Props> = ({ id, user }) => {
  let tankDetail = useSelector((state: any) => {
    return state.entities[id] as TankDetail;
  });

  let headers = {};
  if (user?.imposter) {
    headers = { 'x-datafer-imposter': user.imposter };
  }

  const [{ isPending, status }, refresh] = useRequest(
    getTankDetails(
      { tankId: id },
      {
        transform: body => {
          return { [id]: body as TankDetail };
        },
        update: {
          [id]: (oldValue: any, newValue: any) => {
            return newValue;
          },
        },
        force: !!user?.imposter,
        queryKey: `getTankDetails-${id}-${user!.name}-${user!.imposter}`,
        options: {
          headers,
        },
      }
    )
  );

  if (isPending || !tankDetail) {
    return (
      <div className={styles.loading}>
        <Loading />
      </div>
    );
  }

  const { overview, statusPressure, statusFillLevel } = tankDetail;
  const activeStatus = statusFillLevel || statusPressure!;

  const fillLevelOrderLimit = statusFillLevel?.orderAlertFillLevel || undefined;
  const pressureOrderLimit = fillLevelOrderLimit
    ? undefined
    : statusPressure?.orderAlertFillLevel;

  return (
    <div className={styles.container}>
      <div className={styles.bars}>
        {overview.fillLevelOverview && (
          <div className={`${styles.bar} ${styles.barLeft}`}>
            <BarChart
              max={overview.fillLevelOverview?.maxFillLevel || 1}
              value={overview.fillLevelOverview?.fillLevel || 0}
              orderLimit={fillLevelOrderLimit}
            />
            <div className={styles.values}>
              <LabelValuePair
                label="Füllstand"
                value={`${overview.fillLevelOverview?.fillLevel || '---'} ${
                  overview.fillLevelOverview?.fillLevelUnitOfMeasurement || ''
                }`}
              />
              <LabelValuePair
                label="Vollmarke"
                value={`${overview.fillLevelOverview?.maxFillLevel || '---'} ${
                  overview.fillLevelOverview?.fillLevelUnitOfMeasurement || ''
                }`}
              />
              <LabelValuePair
                label="Bestellmarke"
                value={`${activeStatus.orderAlertFillLevel} ${
                  overview.fillLevelOverview?.fillLevelUnitOfMeasurement || ''
                }`}
              />
            </div>
          </div>
        )}
        {overview.pressureOverview && (
          <div className={styles.bar}>
            <BarChart
              max={overview.pressureOverview?.maxPressure || 1}
              value={overview.pressureOverview?.pressure || 0}
              orderLimit={pressureOrderLimit}
            />
            <div className={styles.values}>
              <LabelValuePair
                label="Druck"
                value={`${overview.pressureOverview?.pressure || '---'} ${
                  overview.pressureOverview?.pressureUnitOfMeasurement || ''
                }`}
              />
              <LabelValuePair
                label="Max"
                value={`${overview.pressureOverview?.maxPressure || '---'} ${
                  overview.pressureOverview?.pressureUnitOfMeasurement || ''
                }`}
              />
              <LabelValuePair
                label="Min"
                value={`${overview.pressureOverview?.minPressure || '---'} ${
                  overview.pressureOverview?.pressureUnitOfMeasurement || ''
                }`}
              />
            </div>
          </div>
        )}
      </div>
      <Spacer />
      <div className={styles.values}>
        <LabelValuePair
          label="24 h-Trend"
          value={
            activeStatus.reach24.getFullYear() > 1971
              ? new Date(activeStatus.reach24).toLocaleString()
              : '---'
          }
          horizontal
        />
        <LabelValuePair
          label="3 h-Trend"
          value={
            activeStatus.reach3.getFullYear() > 1971
              ? new Date(activeStatus.reach3).toLocaleString()
              : '---'
          }
          horizontal
        />
      </div>
      <Spacer />
      <div className={styles.values}>
        <LabelValuePair
          label="DFÜ-Seriennummer"
          value={activeStatus.deviceSerialNo}
          horizontal
        />
        <LabelValuePair
          label="Abladeplatz"
          value={
            activeStatus.unloadingArea !== ''
              ? activeStatus.unloadingArea
              : '---'
          }
          horizontal
        />
        <LabelValuePair
          label="Letzte Messung"
          value={`${activeStatus.measurementCreatedAt.toLocaleString()}`}
          horizontal
        />
      </div>
    </div>
  );
};

export default connect(mapStateToProps)(CardDetail);
