// tslint:disable
/**
 * Datafer
 * Schnittstellenbeschreibung für die Kommunikation zwischen dem neuen Dataferfrontend und Cloud-AI Backend
 *
 * The version of the OpenAPI document: 0.0.2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface AccountInvitationResendRequest
 */
export interface AccountInvitationResendRequest {
  /**
   *
   * @type {string}
   * @memberof AccountInvitationResendRequest
   */
  email: string;
}

export function AccountInvitationResendRequestFromJSON(
  json: any
): AccountInvitationResendRequest {
  return {
    email: json['email'],
  };
}

export function AccountInvitationResendRequestToJSON(
  value?: AccountInvitationResendRequest
): any {
  if (value === undefined) {
    return undefined;
  }
  return {
    email: value.email,
  };
}
