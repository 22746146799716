import { createMuiTheme } from '@material-ui/core/styles';
import { action } from 'typesafe-actions';

const theme = createMuiTheme({
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: '#003169',
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      // light: '#0066ff',
      main: '#eb6617',
      // dark: will be calculated from palette.secondary.main,
      // contrastText: '#ffcc00',
    },
    text: {
      primary: '#003169',
      secondary: 'rgba(#003169,0.5)',
      disabled: 'rgba(#003169,0.5)',
    },
  },
  typography: {
    fontFamily: ['"Source Sans Pro"', 'sans-serif'].join(','),
    fontWeightLight: 400,
    fontWeightRegular: 400,
    fontWeightMedium: 600,
    fontWeightBold: 600,
    allVariants: {
      color: '#003169',
    },
  },
  shape: {
    borderRadius: 5,
  },
});

export default theme;
