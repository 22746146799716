import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import styles from './ChangelogPopUp.module.scss';
import { IconButton } from '@material-ui/core';
import IconClose from '@material-ui/icons/CloseRounded';
import sadHorse from '../assets/images/sadhorse.svg';
import { useRequest } from 'redux-query-react';
import { checkConnectivity } from '../generated/api/src';
import { v4 as uuidv4 } from 'uuid';
import { useSelector } from 'react-redux';
import { RootState } from 'typesafe-actions';

const getConnectivityResponseRayId = (state: RootState) => {
  return state.entities.firewallCheckResponseUuid as string;
};

const getConnectivityQueryState = (state: RootState) => {
  return state.queries.firewallcheck;
};

const getAllQueryErrors = (state: RootState) => {
  return state.errors;
};

const FirewallCheck: React.FC = () => {
  const [expectedRay] = useState<string>(uuidv4());
  const [close, setClose] = useState<boolean>(true);
  const [errorReport, setErrorReport] = useState<string>('');

  const [{ isPending, status: statusCode }] = useRequest(
    checkConnectivity(
      { connectivityCheck: { rayid: expectedRay } },
      {
        transform: responseBody => {
          return {
            firewallCheckResponseUuid: responseBody.rayid,
          };
        },
        update: {
          firewallCheckResponseUuid: (oldValue, newValue) => {
            return newValue;
          },
        },
        queryKey: 'firewallcheck',
      }
    )
  );

  const receivedRay = useSelector(getConnectivityResponseRayId) || 'NONE';
  const connectivityQueryState = useSelector(getConnectivityQueryState) || null;
  const allQueryErrors = useSelector(getAllQueryErrors) || null;
  useEffect(() => {
    if (isPending || statusCode == null) {
      return;
    }

    if (statusCode !== 200 || receivedRay !== expectedRay) {
      setErrorReport(
        JSON.stringify(
          {
            expectedRay,
            receivedRay,
            statusCode,
            queryState: connectivityQueryState,
            queryErrors: allQueryErrors,
          },
          undefined,
          2
        )
      );
      setClose(false);
    }
  }, [receivedRay, statusCode, isPending, setClose]);

  return (
    <div
      className={classNames(styles.background, { [styles.displayNone]: close })}
    >
      <div className={styles.container}>
        <div className={styles.close}>
          <IconButton
            onClick={() => {
              setClose(true);
            }}
          >
            <IconClose />
          </IconButton>
        </div>

        <div className={styles.content} style={{ paddingTop: '4rem' }}>
          <div className={styles.text}>
            <h2>Es gibt ein Problem mit Ihrer Verbindung</h2>
            Wir können unseren Datafer Server nicht erreichen. <br />
            <br />
            Sollten Sie diesen Fehler auch in einigen Minuten noch erhalten,
            deutet dies auf ein Problem mit Ihrem Netzwerk hin. Bitte
            kontaktieren Sie Ihren Systemadministrator und stellen Sie sicher,
            dass folgende Domains nicht durch Ihre Firewall blockiert werden:
            <ul>
              <li>westfalen.com</li>
              <li>cloud-api.net</li>
              <li>amazonaws.com</li>
            </ul>
            Technische Informationen für Entwickler:
            <br />
            <span style={{ fontFamily: 'monospace' }}>{errorReport}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FirewallCheck;
