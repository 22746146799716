import React, { useEffect, useState } from 'react';
import {
  Button,
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  TextField,
} from '@material-ui/core';

import { Casino } from '@material-ui/icons';
import { useMutation } from 'redux-query-react';
import { createUserAccount, setUserPassword } from '../../../generated/api/src';
import Loading from '../../../components/Loading';

function passwordSufficient(password: string): boolean {
  return (
    password.match(/[a-zA-Z]/) != null &&
    password.match(/\d/) != null &&
    // eslint-disable-next-line no-useless-escape
    password.match(/[+\-*_@§\$%&\/\(\)\[\]\{\}]/) != null &&
    password.length >= 8
  );
}

function generatePassword(): string {
  let candidate = '';

  do {
    const availableCharacters =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+-*_@§$%&/()[]{}';
    const pwLen = 64;
    const randomVals = new Uint8Array(pwLen);
    crypto.getRandomValues(randomVals);
    candidate = Array.from(randomVals)
      .map(it => availableCharacters[Number(it) % availableCharacters.length])
      .join('');
    // this condition assure that we fulfill cognitos password requirements
  } while (!passwordSufficient(candidate));

  return candidate;
}

export const EMAIL_REGEX = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/gm;

const EditAccountPassword: React.FC = () => {
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [permPassword, setPermPassword] = useState<boolean>(false);

  useEffect(() => {
    setPassword(generatePassword());
  }, [setPassword]);

  const [{ isPending: accountUpdatePending }, setPasswordHandle] = useMutation(
    () =>
      setUserPassword({
        accountPasswordUpdateRequest: {
          permanent: permPassword,
          password,
          email,
        },
      })
  );

  const onUpdateAccount = () => {
    setPasswordHandle()?.then(() => {
      setEmail('');
    });
  };

  const currentPasswordOk = passwordSufficient(password);
  const currentEmailOk = email.match(EMAIL_REGEX) !== null;
  return (
    <>
      <h3>Passwort festlegen</h3>
      <div style={{ display: 'flex' }}>
        <TextField
          label={'Email-Adresse'}
          value={email}
          onChange={event => setEmail(event.target.value)}
          error={!currentEmailOk && email !== ''}
        />
        <TextField
          style={{ marginLeft: '14px', marginRight: '7px' }}
          label={'Passwort'}
          value={password}
          onChange={event => setPassword(event.target.value)}
          error={!currentPasswordOk}
        />
        <Button
          variant="contained"
          onClick={() => setPassword(generatePassword())}
        >
          <Casino />
        </Button>
        <FormControl>
          <div style={{ paddingLeft: '14px' }}>
            <FormControlLabel
              control={
                <Checkbox
                  onChange={event => setPermPassword(event.target.checked)}
                />
              }
              label={'Permanentes Passwort?'}
              checked={permPassword}
            />
          </div>
        </FormControl>
        <Button
          variant="contained"
          disabled={!currentEmailOk || !currentPasswordOk}
          onClick={onUpdateAccount}
        >
          {accountUpdatePending ? (
            <Loading color={'secondary'} />
          ) : (
            'Passwort setzen'
          )}
        </Button>
      </div>
    </>
  );
};

export default EditAccountPassword;
