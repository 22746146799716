import React from 'react';
import Label from './Label';
import classNames from 'classnames';
import styles from './LabelValuePair.module.scss';

interface Props {
  label: string;
  value: string | undefined;
  large?: boolean;
  horizontal?: boolean;
}

const LabelValuePair: React.FC<Props> = ({
  label,
  value,
  large,
  horizontal,
}) => (
  <div
    className={classNames(
      styles.container,
      { [styles.large]: large },
      { [styles.horizontal]: horizontal }
    )}
  >
    <Label label={label} />
    {value ? <span className={styles.value}>{value}</span> : null}
  </div>
);

export default LabelValuePair;
