import { from, Observable, of, throwError } from 'rxjs';
import { DataferUser } from '../features/inventory/actions';
import { AzureTokenConversionResponse } from '../generated/api/src';
import { apiPost } from './api';
import * as jsonwebtoken from 'jsonwebtoken';
import { catchError, concatMap, map, switchMap, tap } from 'rxjs/operators';

export function switchUser(
  user: DataferUser | null
): Observable<DataferUser | null> {
  return of(user);
}

export function signInWithAzure(
  azureIdToken: string
): Observable<AzureTokenConversionResponse> {
  // @ts-ignore
  return from(
    apiPost<AzureTokenConversionResponse>(
      'DataferBackend',
      `/auth/convert-azure-token`,
      { azureIdToken }
      // @ts-ignore
    ).then(response => response.data)
  );
}

export function refreshCognitoToken(
  cognitoRefreshToken: string
): Observable<AzureTokenConversionResponse> {
  return from(
    apiPost<AzureTokenConversionResponse>(
      'DataferBackend',
      `/auth/refresh-sales-token`,
      { cognitoRefreshToken }
      // @ts-ignore
    ).then(response => response.data)
  );
}

function _writeAzureTokenToStorage(tokens: AzureTokenConversionResponse): void {
  window.localStorage.setItem(
    'SALESFORCEUSER_AWS_TOKENS',
    JSON.stringify(tokens)
  );
}

export function readAzureTokenFromStorage(): Observable<AzureTokenConversionResponse> {
  const item = window.localStorage.getItem('SALESFORCEUSER_AWS_TOKENS');
  if (item === null) {
    return throwError(new Error('no token found'));
  }
  const tokens = JSON.parse(item) as AzureTokenConversionResponse;
  if (tokens.cognitoRefreshToken == null) {
    return throwError(new Error('no refresh token found'));
  }

  const tokenPayload = jsonwebtoken.decode(tokens.cognitoIdToken, {
    json: true,
  });
  // .exp is in SECONDS
  const remainingTokenLife = (tokenPayload?.exp ?? 0) - Date.now() / 1000;
  if (remainingTokenLife > 28 * 60) {
    return of(tokens);
  }

  // refresh tokens with less than 28min lifetime left
  return of(tokens).pipe(
    concatMap(token => refreshCognitoToken(token.cognitoRefreshToken)),
    tap(newTokens => _writeAzureTokenToStorage(newTokens))
  );
}

export function writeAzureTokenToStorage(
  tokens: AzureTokenConversionResponse
): Observable<{}> {
  _writeAzureTokenToStorage(tokens);
  return of({});
}
