import React from 'react';
import { connect } from 'react-redux';
import {
  Button,
  Checkbox,
  Container,
  Link,
  TextField,
} from '@material-ui/core';
import Spacer from '../../../components/Spacer';
import CreateAccount from '../components/CreateAccount';
import EditAccountPassword from '../components/EditAccountPassword';
import ResendInvitation from '../components/ResendInvitation';

const AdministrationPage: React.FC = () => {
  return (
    <Container>
      <h1>Account Administration</h1>
      <span>Bitte vor der Nutzung das Handbuch konsultieren</span>
      <Spacer />
      <CreateAccount />
      <Spacer />
      <EditAccountPassword />
      <Spacer />
      <ResendInvitation />
    </Container>
  );
};

export default AdministrationPage;
