// tslint:disable
/**
 * Datafer
 * Schnittstellenbeschreibung für die Kommunikation zwischen dem neuen Dataferfrontend und Cloud-AI Backend
 *
 * The version of the OpenAPI document: 0.0.2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  TankFillLevelOverview,
  TankFillLevelOverviewFromJSON,
  TankFillLevelOverviewToJSON,
  TankPressureOverview,
  TankPressureOverviewFromJSON,
  TankPressureOverviewToJSON,
} from './';

/**
 *
 * @export
 * @interface TankOverview
 */
export interface TankOverview {
  /**
   * Tplnr aka Technische Platznummer
   * @type {string}
   * @memberof TankOverview
   */
  techId: string;
  /**
   *
   * @type {string}
   * @memberof TankOverview
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof TankOverview
   */
  customerNumber?: string;
  /**
   * SAP: MAKTX aka Material Kurztext
   * @type {string}
   * @memberof TankOverview
   */
  material: string;
  /**
   *
   * @type {string}
   * @memberof TankOverview
   */
  country: string;
  /**
   *
   * @type {TankFillLevelOverview}
   * @memberof TankOverview
   */
  fillLevelOverview?: TankFillLevelOverview;
  /**
   *
   * @type {TankPressureOverview}
   * @memberof TankOverview
   */
  pressureOverview?: TankPressureOverview;
}

export function TankOverviewFromJSON(json: any): TankOverview {
  return {
    techId: json['techId'],
    name: json['name'],
    customerNumber: !exists(json, 'customerNumber')
      ? undefined
      : json['customerNumber'],
    material: json['material'],
    country: json['country'],
    fillLevelOverview: !exists(json, 'fillLevelOverview')
      ? undefined
      : TankFillLevelOverviewFromJSON(json['fillLevelOverview']),
    pressureOverview: !exists(json, 'pressureOverview')
      ? undefined
      : TankPressureOverviewFromJSON(json['pressureOverview']),
  };
}

export function TankOverviewToJSON(value?: TankOverview): any {
  if (value === undefined) {
    return undefined;
  }
  return {
    techId: value.techId,
    name: value.name,
    customerNumber: value.customerNumber,
    material: value.material,
    country: value.country,
    fillLevelOverview: TankFillLevelOverviewToJSON(value.fillLevelOverview),
    pressureOverview: TankPressureOverviewToJSON(value.pressureOverview),
  };
}
