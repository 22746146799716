// tslint:disable
/**
 * Firewall-Check
 * Describes the API to perform firewall checks against
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface ConnectivityCheck
 */
export interface ConnectivityCheck {
  /**
   *
   * @type {string}
   * @memberof ConnectivityCheck
   */
  rayid: string;
}

export function ConnectivityCheckFromJSON(json: any): ConnectivityCheck {
  return {
    rayid: json['rayid'],
  };
}

export function ConnectivityCheckToJSON(value?: ConnectivityCheck): any {
  if (value === undefined) {
    return undefined;
  }
  return {
    rayid: value.rayid,
  };
}
