import React, { useLayoutEffect, useRef, useState } from 'react';
import { TankOverview } from '../../../generated/api/src';
import { Typography } from '@material-ui/core';
import Card from './Card';
import styles from './GasDivision.module.scss';
import ArrowIcon from '@material-ui/icons/KeyboardArrowRightRounded';

interface Props {
  gas: string;
  entities: TankOverview[];
}

const GasDivision: React.FC<Props> = ({ gas, entities }) => {
  // Sort the Tanks according to their remaining contents
  let sortedEntities = entities.filter(it => it.material === gas);
  if (sortedEntities.every(it => !!it.fillLevelOverview)) {
    // If the tanks provide a fill level, sort by fill level
    sortedEntities = sortedEntities.sort(
      (a, b) =>
        a.fillLevelOverview!.fillLevel / a.fillLevelOverview!.maxFillLevel -
        b.fillLevelOverview!.fillLevel / b.fillLevelOverview!.maxFillLevel
    );
  } else if (sortedEntities.every(it => !!it.pressureOverview)) {
    // If the tanks provide a pressure value, but no fill level (as h2 tanks do), use the pressure value
    sortedEntities = sortedEntities.sort(
      (a, b) =>
        a.pressureOverview!.pressure / a.pressureOverview!.maxPressure -
        b.pressureOverview!.pressure / b.pressureOverview!.maxPressure
    );
  }

  const [isScrollable, setIsScrollable] = useState(false);
  const [hasBeenScrolled, setHasBeenScrolled] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const scrollRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (
      scrollRef &&
      scrollRef.current &&
      containerRef &&
      containerRef.current
    ) {
      if (
        scrollRef.current.scrollWidth >
        containerRef.current.clientWidth + 32
      ) {
        setIsScrollable(true);
      }
    }
  });

  useLayoutEffect(() => {
    if (scrollRef && scrollRef.current) {
      scrollRef.current.addEventListener('scroll', () =>
        setHasBeenScrolled(true)
      );
    }
  });

  return (
    <div className={styles.container} ref={containerRef}>
      {isScrollable && !hasBeenScrolled ? (
        <div className={styles.arrow}>
          <ArrowIcon color="secondary" />
        </div>
      ) : null}
      <Typography
        variant="h6"
        component="h3"
        marginBottom={2}
        key={`${gas}-heading`}
      >
        {gas}
      </Typography>
      <div className={styles.row} ref={scrollRef}>
        {sortedEntities.map(it => (
          <div className={styles.item} key={`${it.techId}-div-preview`}>
            <Card id={it.techId} preview key={`${it.techId}-card-preview`} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default GasDivision;
