import React, { useState } from 'react';
import styles from './Error.module.scss';
import IconError from '@material-ui/icons/ErrorRounded';
import IconClose from '@material-ui/icons/CloseRounded';
import { IconButton } from '@material-ui/core';
import classNames from 'classnames';

type Props = {
  error?: string;
};

const ErrorPopup: React.FC<Props> = ({ error }) => {
  const [close, setClose] = useState(false);

  return (
    <div
      className={classNames(styles.background, { [styles.displayNone]: close })}
    >
      <div className={styles.container}>
        <IconError />
        <p>
          <strong>Error:</strong> {error}
        </p>
        <div className={styles.close}>
          <IconButton onClick={() => setClose(true)}>
            <IconClose />
          </IconButton>
        </div>
      </div>
    </div>
  );
};

export default ErrorPopup;
