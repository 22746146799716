// tslint:disable
/**
 * Datafer
 * Schnittstellenbeschreibung für die Kommunikation zwischen dem neuen Dataferfrontend und Cloud-AI Backend
 *
 * The version of the OpenAPI document: 0.0.2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface Partner
 */
export interface Partner {
  /**
   *
   * @type {string}
   * @memberof Partner
   */
  firstName: string;
  /**
   *
   * @type {string}
   * @memberof Partner
   */
  lastName: string;
  /**
   *
   * @type {string}
   * @memberof Partner
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof Partner
   */
  eMail: string;
  /**
   *
   * @type {string}
   * @memberof Partner
   */
  persNo: string;
}

export function PartnerFromJSON(json: any): Partner {
  return {
    firstName: json['firstName'],
    lastName: json['lastName'],
    id: json['id'],
    eMail: json['eMail'],
    persNo: json['persNo'],
  };
}

export function PartnerToJSON(value?: Partner): any {
  if (value === undefined) {
    return undefined;
  }
  return {
    firstName: value.firstName,
    lastName: value.lastName,
    id: value.id,
    eMail: value.eMail,
    persNo: value.persNo,
  };
}
