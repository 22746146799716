// tslint:disable
/**
 * Datafer
 * Schnittstellenbeschreibung für die Kommunikation zwischen dem neuen Dataferfrontend und Cloud-AI Backend
 *
 * The version of the OpenAPI document: 0.0.2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface Customer
 */
export interface Customer {
  /**
   *
   * @type {string}
   * @memberof Customer
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof Customer
   */
  nameAdditional: string;
  /**
   *
   * @type {string}
   * @memberof Customer
   */
  customerNumber: string;
  /**
   *
   * @type {string}
   * @memberof Customer
   */
  zipCode: string;
  /**
   *
   * @type {string}
   * @memberof Customer
   */
  street: string;
  /**
   *
   * @type {string}
   * @memberof Customer
   */
  city: string;
  /**
   *
   * @type {string}
   * @memberof Customer
   */
  houseNumber: string;
  /**
   *
   * @type {string}
   * @memberof Customer
   */
  houseNumberAdditional: string;
}

export function CustomerFromJSON(json: any): Customer {
  return {
    name: json['name'],
    nameAdditional: json['nameAdditional'],
    customerNumber: json['customerNumber'],
    zipCode: json['zipCode'],
    street: json['street'],
    city: json['city'],
    houseNumber: json['houseNumber'],
    houseNumberAdditional: json['houseNumberAdditional'],
  };
}

export function CustomerToJSON(value?: Customer): any {
  if (value === undefined) {
    return undefined;
  }
  return {
    name: value.name,
    nameAdditional: value.nameAdditional,
    customerNumber: value.customerNumber,
    zipCode: value.zipCode,
    street: value.street,
    city: value.city,
    houseNumber: value.houseNumber,
    houseNumberAdditional: value.houseNumberAdditional,
  };
}
