import React from 'react';

interface Props {
  max: number;
  value: number;
  orderLimit?: number;
  disabled?: boolean;
}

const BarChart: React.FC<Props> = ({ max, value, orderLimit, disabled }) => {
  const relativeValue = (x: number) => {
    return (x / max) * 129;
  };

  return (
    <svg width="48" height="129" viewBox="0 0 48 129">
      <rect width="100%" height="100%" opacity="0.2" fill="#003169" />
      <rect
        y={129 - relativeValue(value)}
        width="100%"
        height={relativeValue(value)}
        opacity="0.8"
        fill="#003169"
      />
      {orderLimit && (
        <line
          x1="0"
          x2="100%"
          y1={129 - relativeValue(orderLimit)}
          y2={129 - relativeValue(orderLimit)}
          stroke="#eb6617"
          strokeWidth="2"
          strokeDasharray="4,4"
        />
      )}
      {disabled && <rect width="100%" height="100%" fill="#ddd" />}
    </svg>
  );
};

export default BarChart;
