import React from 'react';
import { Redirect, Route, Switch } from 'react-router';
import NoMatch from '../components/NoMatch';
import InventoryPage from '../features/inventory/screens/InventoryPage';
import AdministrationPage from '../features/administration/screens/AdministrationPage';
import * as inventorySelectors from '../features/inventory/selectors';
import { connect } from 'react-redux';
import { RootState } from 'typesafe-actions';

const mapStateToProps = (state: RootState) => ({
  user: inventorySelectors.user(state.inventory),
});

type Props = ReturnType<typeof mapStateToProps>;

const Routes: React.FC<Props> = ({ user }) => {
  return (
    <div>
      <Switch>
        <Redirect exact path="/" to="/inventory" />
        <Route exact path="/inventory" component={InventoryPage} />
        <Route exact path="/inventory/:customerId" component={InventoryPage} />
        {user?.role === 'admin' && (
          <Route exact path="/admin" component={AdministrationPage} />
        )}
        <Route path="*" component={NoMatch} />
      </Switch>
    </div>
  );
};

export default connect(mapStateToProps)(Routes);
