import React, { useState } from 'react';
import {
  Button,
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  TextField,
} from '@material-ui/core';
import { EMAIL_REGEX } from './EditAccountPassword';
import { useMutation } from 'redux-query-react';
import { createUserAccount } from '../../../generated/api/src';
import Loading from '../../../components/Loading';

const CreateAccount: React.FC = () => {
  const [sendInvitation, setSendInvitation] = useState<boolean>(true);
  const [email, setEmail] = useState<string>('');

  const [
    { isPending: accountCreationPending },
    createAccountHandle,
  ] = useMutation(() =>
    createUserAccount({
      accountCreationRequest: {
        email,
        sendInvitation,
      },
    })
  );

  const onCreateAccount = () => {
    createAccountHandle()?.then(() => setEmail(''));
  };

  const currentEmailOk = email.match(EMAIL_REGEX) !== null;
  return (
    <>
      <h3>Kunden anlegen</h3>
      <div style={{ display: 'flex' }}>
        <TextField
          label={'Email-Adresse'}
          value={email}
          onChange={event => setEmail(event.target.value)}
          error={!currentEmailOk && email !== ''}
        />
        <FormControl>
          <div style={{ paddingLeft: '14px' }}>
            <FormControlLabel
              control={
                <Checkbox
                  onChange={event => setSendInvitation(event.target.checked)}
                />
              }
              label={'Einladung versenden?'}
              checked={sendInvitation}
            />
          </div>
        </FormControl>

        <Button
          variant="contained"
          disabled={!currentEmailOk}
          onClick={onCreateAccount}
        >
          {accountCreationPending ? (
            <Loading color={'secondary'} />
          ) : (
            'Kunde anlegen'
          )}
        </Button>
      </div>
    </>
  );
};

export default CreateAccount;
