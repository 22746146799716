// tslint:disable
/**
 * Firewall-Check
 * Describes the API to perform firewall checks against
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
  HttpMethods,
  QueryConfig,
  ResponseBody,
  ResponseText,
} from 'redux-query';
import * as runtime from '../runtime';
import {
  ConnectivityCheck,
  ConnectivityCheckFromJSON,
  ConnectivityCheckToJSON,
} from '../models';

export interface CheckConnectivityRequest {
  connectivityCheck: ConnectivityCheck;
}

/**
 * Checks whether cloud-api.net is reachable
 */
function checkConnectivityRaw<T>(
  requestParameters: CheckConnectivityRequest,
  requestConfig: runtime.TypedQueryConfig<T, ConnectivityCheck> = {}
): QueryConfig<T> {
  if (
    requestParameters.connectivityCheck === null ||
    requestParameters.connectivityCheck === undefined
  ) {
    throw new runtime.RequiredError(
      'connectivityCheck',
      'Required parameter requestParameters.connectivityCheck was null or undefined when calling checkConnectivity.'
    );
  }

  let queryParameters: any = null;

  const headerParameters: runtime.HttpHeaders = requestConfig?.options?.headers
    ? { ...requestConfig.options.headers }
    : {};

  headerParameters['Content-Type'] = 'application/json';

  const { meta = {} } = requestConfig;

  const config: QueryConfig<T> = {
    url: `${runtime.Configuration.basePath}/v1/check/connectivity`,
    meta,
    update: requestConfig.update,
    queryKey: requestConfig.queryKey,
    optimisticUpdate: requestConfig.optimisticUpdate,
    force: requestConfig.force,
    rollback: requestConfig.rollback,
    options: {
      method: 'POST',
      headers: headerParameters,
    },
    body:
      queryParameters ||
      ConnectivityCheckToJSON(requestParameters.connectivityCheck),
  };

  const { transform: requestTransform } = requestConfig;
  if (requestTransform) {
    config.transform = (body: ResponseBody, text: ResponseBody) =>
      requestTransform(ConnectivityCheckFromJSON(body), text);
  }

  return config;
}

/**
 * Checks whether cloud-api.net is reachable
 */
export function checkConnectivity<T>(
  requestParameters: CheckConnectivityRequest,
  requestConfig?: runtime.TypedQueryConfig<T, ConnectivityCheck>
): QueryConfig<T> {
  return checkConnectivityRaw(requestParameters, requestConfig);
}
