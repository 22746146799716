import React from 'react';
import { CircularProgress } from '@material-ui/core';
import styles from './Loading.module.scss';

interface Props {
  color?: 'primary' | 'secondary' | 'inherit';
  fullscreen?: boolean;
}

const Loading: React.FC<Props> = ({ color, fullscreen }) => (
  // todo figure out centering
  <CircularProgress
    size={fullscreen ? 100 : 20}
    color={color ?? 'primary'}
    className={fullscreen ? styles.fullscreenLoading : undefined}
  />
);

export default Loading;
