import { connectRouter } from 'connected-react-router';
import { History } from 'history';
import { combineReducers } from 'redux';
import { inventoryReducer } from '../features/inventory/reducer';

import { entitiesReducer, errorsReducer, queriesReducer } from 'redux-query';

const createRootReducer = (history: History) =>
  combineReducers({
    inventory: inventoryReducer,
    router: connectRouter(history),
    entities: entitiesReducer,
    queries: queriesReducer,
    errors: errorsReducer,
  });

export default createRootReducer;
