import {
  HttpMethods,
  NetworkHandler,
  NetworkOptions,
  ResponseBody,
  ResponseHeaders,
  ResponseStatus,
  ResponseText,
  Url,
} from 'redux-query';

import { apiDelete, apiGet, apiPost, apiPut } from '../services/api';

interface ApiGatewayResponseHack {
  data: object;
  status: number;
  statusText: string;
  headers: ResponseHeaders;
}

class DataferApiService {
  public awsApiGatewayNetworkInterface(
    url: Url,
    method: HttpMethods,
    networkOptions: NetworkOptions
  ): NetworkHandler {
    let aborted = false;
    return {
      abort: () => {
        aborted = true;
      },
      execute: (
        callback: (
          error: any,
          status: ResponseStatus,
          responseBody?: ResponseBody,
          responseText?: ResponseText,
          responseHeaders?: ResponseHeaders
        ) => void
      ) => {
        url = url.replace(
          'https://v3hyupecc9.execute-api.eu-central-1.amazonaws.com/prod',
          ''
        );

        let backend = 'DataferBackend';
        // terrible hack, please look away
        if (url.includes('/v1/check/connectivity')) {
          backend = 'FirewallCheckBackend';
        }

        // todo: for some godforsaken reason, query params are unified with the body. If they are used at the same time, we are effed right now.
        let queryParams = {};
        let body = {};
        const headers = {
          ...networkOptions.headers,
          // 'x-datafer-imposter': 'info@renner-powder.de' // h2 tanks
          // 'x-datafer-imposter': 'samuel.manissier@groupesamat.com' // viiiele tanks
          // 'x-datafer-imposter': 'richard.lhuillery@arcelormittal.com' // drei der gleichen sorte
          // 'x-datafer-imposter': 'schulte@poll-schornsteine.de'
          // 'x-datafer-imposter': 'warte@emter-gmbh.de', // <= fails
          // 'x-datafer-imposter': 'u.eberle@sapiolife.de' // <= keine tanks
          // 'x-datafer-imposter': 'peter.horsthemke@venti-oelde.de'
        };

        if (method === 'GET' && networkOptions.body) {
          queryParams = networkOptions.body;
        } else if (method === 'POST' && networkOptions.body) {
          body = networkOptions.body;
        }

        let callPromise: Promise<ApiGatewayResponseHack>;
        switch (method) {
          case 'GET':
            callPromise = apiGet(backend, url, queryParams, headers);
            break;
          case 'PUT':
            callPromise = apiPut(backend, url, body, queryParams, headers);
            break;
          case 'POST':
            callPromise = apiPost(backend, url, body, queryParams, headers);
            break;
          case 'DELETE':
            callPromise = apiDelete(backend, url, queryParams, headers);
            break;
          case 'PATCH':
          case 'HEAD':
          case 'OPTIONS':
          default:
            throw new Error(`unsupported callback ${method}`);
        }

        callPromise
          .then((response: ApiGatewayResponseHack) => {
            if (aborted) {
              return;
            }
            callback(
              null,
              response.status as number,
              response.data,
              response.statusText,
              response.headers
            );
          })
          .catch(error => {
            let status = 500;
            let text = '';
            let headers = {};
            let body = {};

            if (error.response) {
              status = error.response.status;
              text = error.response.statusText;
              body = error.response.data;
              headers = error.response.headers;
            }

            callback(error, status, body, text, headers);
          });
      },
    };
  }
}

export default new DataferApiService();
