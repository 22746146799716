// tslint:disable
/**
 * Datafer
 * Schnittstellenbeschreibung für die Kommunikation zwischen dem neuen Dataferfrontend und Cloud-AI Backend
 *
 * The version of the OpenAPI document: 0.0.2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface TankPressureOverview
 */
export interface TankPressureOverview {
  /**
   * Der minimal erlaubte Druck im Tank
   * @type {number}
   * @memberof TankPressureOverview
   */
  minPressure: number;
  /**
   * Der maximal erlaubte Druck im Tank
   * @type {number}
   * @memberof TankPressureOverview
   */
  maxPressure: number;
  /**
   * Der aktuelle Druck im Tank
   * @type {number}
   * @memberof TankPressureOverview
   */
  pressure: number;
  /**
   * Unit of measurement for pressure values
   * @type {string}
   * @memberof TankPressureOverview
   */
  pressureUnitOfMeasurement: string;
}

export function TankPressureOverviewFromJSON(json: any): TankPressureOverview {
  return {
    minPressure: json['minPressure'],
    maxPressure: json['maxPressure'],
    pressure: json['pressure'],
    pressureUnitOfMeasurement: json['pressureUnitOfMeasurement'],
  };
}

export function TankPressureOverviewToJSON(value?: TankPressureOverview): any {
  if (value === undefined) {
    return undefined;
  }
  return {
    minPressure: value.minPressure,
    maxPressure: value.maxPressure,
    pressure: value.pressure,
    pressureUnitOfMeasurement: value.pressureUnitOfMeasurement,
  };
}
