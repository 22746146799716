// tslint:disable
/**
 * Datafer
 * Schnittstellenbeschreibung für die Kommunikation zwischen dem neuen Dataferfrontend und Cloud-AI Backend
 *
 * The version of the OpenAPI document: 0.0.2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  TankOverview,
  TankOverviewFromJSON,
  TankOverviewToJSON,
  TankStatus,
  TankStatusFromJSON,
  TankStatusToJSON,
} from './';

/**
 *
 * @export
 * @interface TankDetail
 */
export interface TankDetail {
  /**
   *
   * @type {TankOverview}
   * @memberof TankDetail
   */
  overview: TankOverview;
  /**
   *
   * @type {TankStatus}
   * @memberof TankDetail
   */
  statusPressure?: TankStatus;
  /**
   *
   * @type {TankStatus}
   * @memberof TankDetail
   */
  statusFillLevel?: TankStatus;
}

export function TankDetailFromJSON(json: any): TankDetail {
  return {
    overview: TankOverviewFromJSON(json['overview']),
    statusPressure: !exists(json, 'statusPressure')
      ? undefined
      : TankStatusFromJSON(json['statusPressure']),
    statusFillLevel: !exists(json, 'statusFillLevel')
      ? undefined
      : TankStatusFromJSON(json['statusFillLevel']),
  };
}

export function TankDetailToJSON(value?: TankDetail): any {
  if (value === undefined) {
    return undefined;
  }
  return {
    overview: TankOverviewToJSON(value.overview),
    statusPressure: TankStatusToJSON(value.statusPressure),
    statusFillLevel: TankStatusToJSON(value.statusFillLevel),
  };
}
