import React, { useLayoutEffect, useRef, useState } from 'react';
import styles from './CardStatistics.module.scss';
import LineChart from './LineChart';
import Label from './Label';
import DateTimeRangePicker from './DateTimeRangePicker';
import { Button, Modal, Typography } from '@material-ui/core';
import IconDownload from '@material-ui/icons/SaveAltRounded';
import IconClose from '@material-ui/icons/CloseRounded';
import { connect, useSelector } from 'react-redux';
import {
  getTankHistory,
  GetTankHistoryExportDownloadEnum,
  TankHistory,
} from '../../../generated/api/src';
import { useRequest } from 'redux-query-react';
import Loading from '../../../components/Loading';
import classNames from 'classnames';
import useWindowWidth from '../../../hooks/useWindowWidth';
import * as inventorySelectors from '../selectors';
import { RootState } from 'typesafe-actions';
import Spacer from '../../../components/Spacer';
import HistoryDownloadButton from './HistoryDownloadButton';
import IconHtml from '../../../assets/images/icon_html.svg';
import IconXls from '../../../assets/images/icon_xls.svg';

const mapStateToProps = (state: RootState) => ({
  user: inventorySelectors.user(state.inventory),
});

type Props = ReturnType<typeof mapStateToProps> & { id: string };

const CardStatistics: React.FC<Props> = ({ id, user }) => {
  const [level, setLevel] = useState(true);
  const [popUp, setPopUp] = useState(false);

  const [from, setFrom] = useState<Date>(
    new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)
  );
  const [to, setTo] = useState<Date>(new Date(Date.now()));

  const entityStoreId = `${id}-history`;
  let tankHistory = useSelector((state: any) => {
    return state.entities[entityStoreId] as TankHistory;
  });

  let headers = {};
  if (user?.imposter) {
    headers = { 'x-datafer-imposter': user.imposter };
  }

  const [{ isPending, status }, refresh] = useRequest(
    getTankHistory(
      { tankId: id, from: from, to: to },
      {
        transform: body => {
          return { [entityStoreId]: body as TankHistory };
        },
        update: {
          [entityStoreId]: (oldValue: any, newValue: any) => {
            return newValue;
          },
        },
        force: !!user?.imposter,
        queryKey: `getTankHistory-${id}-${from}-${to}-${user!.name}-${
          user!.imposter
        }`,
        options: {
          headers,
        },
      }
    )
  );

  const { width } = useWindowWidth();
  const loadingRef = useRef<HTMLDivElement>(null);
  const [loadingHeight, setLoadingHeight] = useState<number>(0);

  useLayoutEffect(() => {
    if (loadingRef && loadingRef.current) {
      setLoadingHeight(loadingRef.current.clientWidth);
    }
  });

  const ChartLoading = () => {
    if (width > 960) {
      return (
        <div className={styles.lineCharts}>
          <div
            ref={loadingRef}
            style={{ height: loadingHeight }}
            className={styles.loading}
          >
            <Loading />
          </div>
          <div
            ref={loadingRef}
            style={{ height: loadingHeight }}
            className={styles.loading}
          >
            <Loading />
          </div>
        </div>
      );
    } else {
      return (
        <div
          ref={loadingRef}
          style={{ height: loadingHeight }}
          className={styles.loading}
        >
          <Loading />
        </div>
      );
    }
  };

  const Chart = () => {
    if (width > 960) {
      return (
        <div className={styles.lineCharts}>
          <div>
            <LineChart
              data={tankHistory.fillLevelHistory}
              unit={tankHistory.fillLevelUnitOfMeasurement}
            />
          </div>
          <div>
            <LineChart
              data={tankHistory.pressureHistory}
              unit={tankHistory.pressureUnitOfMeasurement}
            />
          </div>
        </div>
      );
    } else {
      if (level) {
        return (
          <LineChart
            data={tankHistory.fillLevelHistory!}
            unit={tankHistory.fillLevelUnitOfMeasurement}
          />
        );
      } else {
        return (
          <LineChart
            data={tankHistory.pressureHistory!}
            unit={tankHistory.pressureUnitOfMeasurement}
          />
        );
      }
    }
  };

  const ExportPopUp = () => {
    return (
      <Modal open={popUp} onClose={() => setPopUp(false)}>
        <div className={styles.popup}>
          <IconClose className={styles.close} onClick={() => setPopUp(false)} />
          <h2>Exportieren</h2>
          <p>Format wählen und Datei herunterladen.</p>
          <div className={styles.fileButtonRow}>
            <HistoryDownloadButton
              id={id}
              from={from}
              to={to}
              format={GetTankHistoryExportDownloadEnum.Csv}
              user={user}
            />
            <HistoryDownloadButton
              id={id}
              from={from}
              to={to}
              format={GetTankHistoryExportDownloadEnum.Html}
              user={user}
            />
            <HistoryDownloadButton
              id={id}
              from={from}
              to={to}
              format={GetTankHistoryExportDownloadEnum.Xls}
              user={user}
            />
          </div>
        </div>
      </Modal>
    );
  };

  return (
    <div className={styles.container}>
      {width < 960 ? (
        <div className={styles.buttons}>
          <button
            onClick={() => setLevel(true)}
            className={classNames({ [styles.active]: level })}
          >
            <Typography variant="button" component="span">
              Füllstand
            </Typography>
          </button>
          <button
            onClick={() => setLevel(false)}
            className={classNames({ [styles.active]: !level })}
          >
            <Typography variant="button" component="span">
              Druck
            </Typography>
          </button>
        </div>
      ) : null}

      {isPending || !tankHistory ? <ChartLoading /> : <Chart />}

      <div>
        <Label label="Datum / Von – Bis" />
        <DateTimeRangePicker
          initialStartDate={from}
          initialEndDate={to}
          startDateSetter={setFrom}
          endDateSetter={setTo}
        />
      </div>
      <Spacer />
      <Button
        color="secondary"
        startIcon={<IconDownload />}
        onClick={() => setPopUp(true)}
      >
        Exportieren
      </Button>
      {popUp && <ExportPopUp />}
    </div>
  );
};

export default connect(mapStateToProps)(CardStatistics);
