import React from 'react';
import { useLocation } from 'react-router';

const NoMatch: React.FC = () => {
  const location = useLocation();

  return <h3>No match for {location.pathname}</h3>;
};

export default NoMatch;
