// tslint:disable
/**
 * Datafer
 * Schnittstellenbeschreibung für die Kommunikation zwischen dem neuen Dataferfrontend und Cloud-AI Backend
 *
 * The version of the OpenAPI document: 0.0.2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
  HttpMethods,
  QueryConfig,
  ResponseBody,
  ResponseText,
} from 'redux-query';
import * as runtime from '../runtime';
import {
  AccountCreationRequest,
  AccountCreationRequestFromJSON,
  AccountCreationRequestToJSON,
  AccountInvitationResendRequest,
  AccountInvitationResendRequestFromJSON,
  AccountInvitationResendRequestToJSON,
  AccountPasswordUpdateRequest,
  AccountPasswordUpdateRequestFromJSON,
  AccountPasswordUpdateRequestToJSON,
  ErrorMessage,
  ErrorMessageFromJSON,
  ErrorMessageToJSON,
} from '../models';

export interface CreateUserAccountRequest {
  accountCreationRequest: AccountCreationRequest;
}

export interface ResendUserInvitationRequest {
  accountInvitationResendRequest: AccountInvitationResendRequest;
}

export interface SetUserPasswordRequest {
  accountPasswordUpdateRequest: AccountPasswordUpdateRequest;
}

/**
 * Create a new user account
 */
function createUserAccountRaw<T>(
  requestParameters: CreateUserAccountRequest,
  requestConfig: runtime.TypedQueryConfig<T, void> = {}
): QueryConfig<T> {
  if (
    requestParameters.accountCreationRequest === null ||
    requestParameters.accountCreationRequest === undefined
  ) {
    throw new runtime.RequiredError(
      'accountCreationRequest',
      'Required parameter requestParameters.accountCreationRequest was null or undefined when calling createUserAccount.'
    );
  }

  let queryParameters: any = null;

  const headerParameters: runtime.HttpHeaders = requestConfig?.options?.headers
    ? { ...requestConfig.options.headers }
    : {};

  headerParameters['Content-Type'] = 'application/json';

  const { meta = {} } = requestConfig;

  meta.authType = ['api_key', 'header'];
  meta.authType = ['api_key', 'header'];
  const config: QueryConfig<T> = {
    url: `${runtime.Configuration.basePath}/admin/create-account`,
    meta,
    update: requestConfig.update,
    queryKey: requestConfig.queryKey,
    optimisticUpdate: requestConfig.optimisticUpdate,
    force: requestConfig.force,
    rollback: requestConfig.rollback,
    options: {
      method: 'POST',
      headers: headerParameters,
    },
    body:
      queryParameters ||
      AccountCreationRequestToJSON(requestParameters.accountCreationRequest),
  };

  const { transform: requestTransform } = requestConfig;
  if (requestTransform) {
  }

  return config;
}

/**
 * Create a new user account
 */
export function createUserAccount<T>(
  requestParameters: CreateUserAccountRequest,
  requestConfig?: runtime.TypedQueryConfig<T, void>
): QueryConfig<T> {
  return createUserAccountRaw(requestParameters, requestConfig);
}

/**
 * Resend the invitation mail for a given user
 */
function resendUserInvitationRaw<T>(
  requestParameters: ResendUserInvitationRequest,
  requestConfig: runtime.TypedQueryConfig<T, void> = {}
): QueryConfig<T> {
  if (
    requestParameters.accountInvitationResendRequest === null ||
    requestParameters.accountInvitationResendRequest === undefined
  ) {
    throw new runtime.RequiredError(
      'accountInvitationResendRequest',
      'Required parameter requestParameters.accountInvitationResendRequest was null or undefined when calling resendUserInvitation.'
    );
  }

  let queryParameters: any = null;

  const headerParameters: runtime.HttpHeaders = requestConfig?.options?.headers
    ? { ...requestConfig.options.headers }
    : {};

  headerParameters['Content-Type'] = 'application/json';

  const { meta = {} } = requestConfig;

  meta.authType = ['api_key', 'header'];
  meta.authType = ['api_key', 'header'];
  const config: QueryConfig<T> = {
    url: `${runtime.Configuration.basePath}/admin/resend-invitation`,
    meta,
    update: requestConfig.update,
    queryKey: requestConfig.queryKey,
    optimisticUpdate: requestConfig.optimisticUpdate,
    force: requestConfig.force,
    rollback: requestConfig.rollback,
    options: {
      method: 'POST',
      headers: headerParameters,
    },
    body:
      queryParameters ||
      AccountInvitationResendRequestToJSON(
        requestParameters.accountInvitationResendRequest
      ),
  };

  const { transform: requestTransform } = requestConfig;
  if (requestTransform) {
  }

  return config;
}

/**
 * Resend the invitation mail for a given user
 */
export function resendUserInvitation<T>(
  requestParameters: ResendUserInvitationRequest,
  requestConfig?: runtime.TypedQueryConfig<T, void>
): QueryConfig<T> {
  return resendUserInvitationRaw(requestParameters, requestConfig);
}

/**
 * Updates the password for a user account
 */
function setUserPasswordRaw<T>(
  requestParameters: SetUserPasswordRequest,
  requestConfig: runtime.TypedQueryConfig<T, void> = {}
): QueryConfig<T> {
  if (
    requestParameters.accountPasswordUpdateRequest === null ||
    requestParameters.accountPasswordUpdateRequest === undefined
  ) {
    throw new runtime.RequiredError(
      'accountPasswordUpdateRequest',
      'Required parameter requestParameters.accountPasswordUpdateRequest was null or undefined when calling setUserPassword.'
    );
  }

  let queryParameters: any = null;

  const headerParameters: runtime.HttpHeaders = requestConfig?.options?.headers
    ? { ...requestConfig.options.headers }
    : {};

  headerParameters['Content-Type'] = 'application/json';

  const { meta = {} } = requestConfig;

  meta.authType = ['api_key', 'header'];
  meta.authType = ['api_key', 'header'];
  const config: QueryConfig<T> = {
    url: `${runtime.Configuration.basePath}/admin/set-password`,
    meta,
    update: requestConfig.update,
    queryKey: requestConfig.queryKey,
    optimisticUpdate: requestConfig.optimisticUpdate,
    force: requestConfig.force,
    rollback: requestConfig.rollback,
    options: {
      method: 'POST',
      headers: headerParameters,
    },
    body:
      queryParameters ||
      AccountPasswordUpdateRequestToJSON(
        requestParameters.accountPasswordUpdateRequest
      ),
  };

  const { transform: requestTransform } = requestConfig;
  if (requestTransform) {
  }

  return config;
}

/**
 * Updates the password for a user account
 */
export function setUserPassword<T>(
  requestParameters: SetUserPasswordRequest,
  requestConfig?: runtime.TypedQueryConfig<T, void>
): QueryConfig<T> {
  return setUserPasswordRaw(requestParameters, requestConfig);
}
