import React from 'react';
import styles from './Label.module.scss';

interface Props {
  label: string;
}

const Label: React.FC<Props> = ({ label }) => (
  <label className={styles.label}>{label}</label>
);

export default Label;
